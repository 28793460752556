import { LocalNotifications } from '@capacitor/local-notifications';
import { sleep } from '$lib/utils.js';

let timesRun = 0;
let beingRun;
export async function setPowerNotifications(powerPeaks, powerDips, wakeup) {
    timesRun = timesRun++
    await sleep(timesRun * 2000)
    if (beingRun) return;
    beingRun = true;
    let pstate = await LocalNotifications.requestPermissions();
    // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied';;
    if (pstate.display === 'granted' && powerPeaks && powerPeaks?.length) {
        let existingNotifications = await LocalNotifications.getPending().then(
            (notifObject) => notifObject.notifications
        );
        if (existingNotifications && existingNotifications?.length) LocalNotifications.cancel({
            notifications: existingNotifications
        });
        let existingPowerNotifications = existingNotifications.filter(
            (notif) => notif.title.includes('Get ready to peak') || notif.title.includes('Prepare for your dip')
        );
        if (existingPowerNotifications && existingPowerNotifications?.length)
            await LocalNotifications.cancel({
                notifications: existingPowerNotifications
            });
        let existingGmNotifications = existingNotifications.filter(
            (notif) => notif.title.includes('Good morning!')
        );
        if (existingGmNotifications && existingGmNotifications?.length)
            await LocalNotifications.cancel({
                notifications: existingGmNotifications
            });
        let peaksToNotif = powerPeaks.filter((peak) => peak.position < 1140);

        await LocalNotifications.schedule({
            notifications: [
                {
                    id: 1000 * Math.round(999 * Math.random()) + Math.round(999 * Math.random()),
                    title: 'Good morning! Feeling powerful?',
                    body: `Check out your Power curve for the day, and see what's been affecting it!`,
                    schedule: {
                        repeats: true,
                        // at: dayjs()
                        on: {
                            hour: wakeup ? Number(wakeup.add(15, 'minutes').format('HH:mm').split(':')[0]) : 8,
                            minute: wakeup
                                ? Number(wakeup.add(15, 'minutes').format('HH:mm').split(':')[1])
                                : 45
                        }
                    }
                },
                ...(powerPeaks || [])
                    .filter((peak) => peak.position < 1140)
                    .slice(0, 2)
                    .map((peak) => dayjs(peak.timestamp).subtract(120, 'minutes').format('HH:mm'))
                    .map((time, i) => {
                        return {
                            id: 1000 * i + Math.round(999 * Math.random()),
                            title: 'Get ready to peak in 30m',
                            body: `Clear your schedule and line up your most challenging work! Try a Focus session.`,
                            schedule: {
                                repeats: true,
                                // at: dayjs()
                                on: {
                                    hour: Number(time.split(':')[0]),
                                    minute: Number(time.split(':')[1])
                                }
                            }
                        };
                    }),
                ...(powerDips || [])
                    .filter((dip) => dip.position < 1140)
                    .slice(0, 2)
                    .map((dip) => dayjs(dip.timestamp).subtract(120, 'minutes').format('HH:mm'))
                    .map((time, i) => {
                        return {
                            id: 1000 * i + Math.round(999 * Math.random()),
                            title: 'Prepare for your dip in 30m',
                            body: `Check the app for what you can do to stay productive!`,
                            schedule: {
                                repeats: true,
                                // at: dayjs()
                                on: {
                                    hour: Number(time.split(':')[0]),
                                    minute: Number(time.split(':')[1])
                                }
                            }
                        };
                    })
            ]
        });

        let newPending = await LocalNotifications.getPending();
        beingRun = false;
    }
}