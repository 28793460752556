<script>
	// import AllLocationsMap from '../components/charts/AllLocationsMap.svelte';
	// import Topics from '../components/charts/Topics.svelte';
	import Feed from './Feed.svelte';

	// import SelfMoodSummary from '../components/charts/SelfMoodSummary.svelte';
	// import Relationships from '../components/charts/Relationships.svelte';
	// import SpotifyAttributes from '../components/charts/SpotifyAttributes.svelte';
	import RecentSummary from '../components/charts/RecentSummary.svelte';
	import Navigation from './Navigation.svelte';
	import NavColumn from './NavColumn.svelte';

	// import Playground from '../sections/Playground.svelte';
	import Paywall from '../components/Paywall.svelte';

	import DatePicker from '../components/DatePicker.svelte';

	import {
		DateRange,
		IsNative,
		Path,
		NotPaid,
		TodaysData,
		Data,
		EthiClaims,
		RenderDelayedContent
	} from '$lib/store.js';
	import { setTodayData } from '$lib/dataProcessing.js';
	import { makeDecimalIntoTime } from '$lib/connectUtils.js';
	import dayjs from 'dayjs';

	let shifting;
	let selectedTimePeriod = 'day';
	let mostRecent = {
		day: 0,
		week: 0,
		month: 0
	};
	let prevPeriodsInPast = 0;
	let shiftPeriods = async (amount, timePeriod, jumpTo) => {
		timePeriod = timePeriod || selectedTimePeriod;
		let daysToShift =
			amount *
				(timePeriod === 'day'
					? 1
					: timePeriod === 'week'
					  ? 7
					  : $DateRange?.start?.endOf?.('month').date() || 30) +
			(timePeriod === 'month' ? -3 : 0);
		let generalDistance = $DateRange.daysInPast + daysToShift;
		console.log('new dip includes this date', generalDistance, daysToShift);
		mostRecent[timePeriod] = $DateRange.daysInPast + daysToShift;
		let newStartOfPeriod = dayjs()
			.subtract(4, 'hour')
			.subtract(
				!startOfWeek.format('dddd') === 'Sunday' && timePeriod === 'week'
					? $DateRange.daysInPast + daysToShift + 1
					: $DateRange.daysInPast + daysToShift,
				'day'
			)
			.startOf(selectedTimePeriod);
		let newDaysInPast = jumpTo || dayjs().subtract(4, 'hour').diff(newStartOfPeriod, 'day');
		// console.log('new dip', newDaysInPast, $DateRange.daysInPast + daysToShift, {
		// 	daysToShift,
		// 	newStartOfPeriod: newStartOfPeriod.format()
		// });

		$DateRange = {
			end: dayjs()
				.subtract(4, 'hour')
				.subtract(newDaysInPast, 'day')
				.endOf('day')
				.add(4, 'hour')
				.hour(4)
				.startOf('hour'),
			start: dayjs()
				.subtract(4, 'hour')
				.subtract(newDaysInPast + 7, 'day')
				.endOf('day')
				.add(4, 'hour')
				.hour(4)
				.startOf('hour'),
			daysInPast: newDaysInPast,
			daysInRange: Array(7)
				.fill()
				.map((p, i) =>
					dayjs()
						.subtract(4, 'hour')
						.subtract(6, 'day')
						.startOf('day')
						.subtract(newDaysInPast, 'day')
						.add(i, 'day')
						.format('YYYY-MM-DD')
				),
			todayIn10Minutes: Array(24 * 6)
				.fill()
				.map((a, i) =>
					dayjs()
						.subtract(4, 'hour')
						.subtract(newDaysInPast, 'day')
						.startOf('day')
						.hour(4)
						.startOf('hour')
						.add(i * 10, 'minute')
				)
		};
		console.log($DateRange.daysInPast);
		await setTodayData($DateRange.daysInPast, 'setFunc');
		prevPeriodsInPast = dayjs().diff(newStartOfPeriod, selectedTimePeriod);
		selectedTimePeriod = selectedTimePeriod;
		if (
			selectedTimePeriod === 'day' &&
			(!$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime ||
				$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime < 10) &&
			$TodaysData?.activitywatch?.lastUpdated < Date.now() - 10 * 60 * 1000
		) {
			shifting = true;
			await query('screentime')($DateRange.daysInPast, true, true);
			await setTodayData($DateRange.daysInPast, 'setFunc2');
			shifting = false;
		}
	};

	export let showWhenPathIs = '/';
	export let showcaseSlot = undefined;
	export let contentSlot = undefined;
	export let components = [
		{
			title: 'Recent',
			component: RecentSummary,
			props: {},
			icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
    </svg>`
		}
		// 	{
		// 		title: 'Time',
		// 		component: TimeTracked,
		// 		icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//   <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
		// </svg>`
		// 	},
		// {
		// 	title: 'Insights',
		// 	component: Insights,
		// 	props: {},
		// 	icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//   <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
		// </svg>`
		// },
		// 		{
		// 			title: 'Trends',
		// 			component: Trends,
		// 			props: {},
		// 			icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//   <path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z" clip-rule="evenodd" />
		// </svg>`
		// 		},
		// 		{
		// 			title: 'Health',
		// 			component: Health,
		// 			props: {},
		// 			icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//         <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
		//         </svg>`
		// 		},
		// 		{
		// 			title: 'Mind',
		// 			component: SelfMoodSummary,
		// 			props: {},
		// 			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
		// <path d="M10 1.29238C9.57336 0.522656 8.75309 0 7.8125 0C6.88355 0 6.03242 0.519258 5.6018 1.33531C4.51996 1.62508 3.75 2.61629 3.75 3.75C3.75 3.7523 3.75 3.75441 3.75 3.75672C3.36027 4.05016 3.125 4.51066 3.125 5C3.125 5.05141 3.12805 5.10301 3.13414 5.15594C2.35199 5.67445 1.875 6.55305 1.875 7.5C1.875 8.04961 2.03277 8.5759 2.33277 9.03367C2.23723 9.23875 2.1875 9.46121 2.1875 9.6875C2.1875 9.79445 2.19848 9.90019 2.21984 10.0038C1.4325 10.4417 0.9375 11.2711 0.9375 12.1875C0.9375 13.2802 1.65254 14.232 2.66051 14.5619C2.55461 14.798 2.5 15.0517 2.5 15.3125C2.5 16.1366 3.03926 16.8558 3.81012 17.0987C4.05824 18.2323 5.07141 19.0625 6.25 19.0625C6.43035 19.0625 6.61043 19.0425 6.78773 19.0029C7.10906 19.6094 7.73988 20 8.4375 20C9.08934 20 9.66391 19.6654 10 19.1593V1.29238Z"/>
		// <path d="M10.3125 10C10.1398 10 10 9.86008 10 9.6875C10 8.82598 9.29902 8.125 8.4375 8.125C8.12469 8.125 7.82289 8.21699 7.565 8.39109C7.44691 8.4709 7.28941 8.46066 7.1823 8.36563C7.05902 8.2559 6.85117 8.125 6.5625 8.125C6.38977 8.125 6.25 7.98508 6.25 7.8125C6.25 7.63992 6.38977 7.5 6.5625 7.5C6.86371 7.5 7.15758 7.58957 7.4109 7.75543C7.72551 7.58773 8.07648 7.5 8.4375 7.5C9.64355 7.5 10.625 8.48129 10.625 9.6875C10.625 9.86008 10.4852 10 10.3125 10Z" />
		// <path d="M4.68781 4.6875C5.4291 4.6875 6.12824 4.97312 6.65711 5.49195C6.7798 5.61266 6.97754 5.61098 7.09898 5.4877C7.21984 5.36457 7.21801 5.16664 7.09473 5.04582C6.44836 4.41176 5.59355 4.0625 4.68781 4.0625C4.1782 4.0625 3.69582 4.17695 3.26039 4.3768C3.17426 4.57105 3.12531 4.78215 3.12531 5C3.12531 5.05141 3.12836 5.10301 3.13445 5.15594C3.13078 5.15836 3.1277 5.16137 3.12402 5.16383C3.57152 4.86332 4.10941 4.6875 4.68781 4.6875Z" />
		// <path d="M8.43691 14.0625C8.43691 13.8899 8.29715 13.75 8.12441 13.75C7.95168 13.75 7.81191 13.8899 7.81191 14.0625C7.81191 14.7518 7.25129 15.3125 6.56191 15.3125C6.0068 15.3125 5.51426 14.9356 5.3641 14.3959C5.33664 14.2978 5.2634 14.2192 5.16727 14.1852C5.07145 14.1511 4.96461 14.1659 4.88191 14.225C4.45621 14.5276 3.95664 14.6875 3.43691 14.6875C3.16551 14.6875 2.90469 14.6426 2.6598 14.5622C2.57332 14.7552 2.52305 14.9603 2.50684 15.1711C2.80074 15.2629 3.11312 15.3125 3.43691 15.3125C3.95633 15.3125 4.45984 15.1849 4.91242 14.9406C5.23258 15.5427 5.8643 15.9375 6.56191 15.9375C7.59586 15.9375 8.43691 15.0964 8.43691 14.0625Z" />
		// <path d="M19.0625 12.1875C19.0625 11.2711 18.5675 10.4418 17.7802 10.0038C17.8015 9.90023 17.8125 9.79449 17.8125 9.6875C17.8125 9.46121 17.7628 9.23875 17.6672 9.03367C17.9672 8.5759 18.125 8.04961 18.125 7.5C18.125 6.55305 17.648 5.67445 16.8659 5.15594C16.872 5.10301 16.875 5.05141 16.875 5C16.875 4.51066 16.6397 4.05016 16.25 3.75672C16.25 3.75441 16.25 3.7523 16.25 3.75C16.25 2.61629 15.48 1.62508 14.3982 1.33531C13.9676 0.519258 13.1164 0 12.1875 0C11.2469 0 10.4266 0.522656 10 1.29238V19.1592C10.3361 19.6654 10.9107 20 11.5625 20C12.2601 20 12.8909 19.6094 13.2123 19.0029C13.3896 19.0425 13.5696 19.0625 13.75 19.0625C14.9286 19.0625 15.9418 18.2323 16.1899 17.0987C16.9607 16.8558 17.5 16.1366 17.5 15.3125C17.5 15.0517 17.4454 14.798 17.3395 14.5619C18.3475 14.232 19.0625 13.2802 19.0625 12.1875Z"/>
		// <path d="M10.6254 5.90621C10.625 5.9166 10.625 5.92711 10.625 5.9375C10.625 6.97145 11.4661 7.8125 12.5 7.8125C13.534 7.8125 14.375 6.97145 14.375 5.9375C14.375 5.76492 14.2353 5.625 14.0625 5.625C13.8898 5.625 13.75 5.76492 13.75 5.9375C13.75 6.62676 13.1894 7.1875 12.5 7.1875C11.8107 7.1875 11.25 6.62676 11.25 5.9375C11.25 5.85219 11.2586 5.76723 11.2757 5.68496C11.2949 5.59141 11.2705 5.49422 11.2091 5.42113C11.1478 5.34805 11.0523 5.30137 10.961 5.30945C10.9433 5.30992 10.9262 5.31176 10.9375 5.3125C10.4205 5.3125 10 4.89195 10 4.375V5.6177C10.1826 5.75578 10.393 5.85891 10.6254 5.90621Z" />
		// <path d="M11.5625 12.8125C11.9235 12.8125 12.2742 12.7248 12.5891 12.5571C12.8424 12.7229 13.1363 12.8125 13.4375 12.8125C13.6102 12.8125 13.75 12.6726 13.75 12.5C13.75 12.3274 13.6102 12.1875 13.4375 12.1875C13.1488 12.1875 12.941 12.0566 12.8177 11.9469C12.7106 11.8518 12.5534 11.8416 12.435 11.9212C12.1768 12.0955 11.875 12.1875 11.5625 12.1875C10.701 12.1875 10 11.4865 10 10.625V12.153C10.3974 12.5593 10.9507 12.8125 11.5625 12.8125Z" />
		// <path d="M16.8658 5.15594C16.8719 5.10301 16.875 5.05141 16.875 5C16.875 4.78215 16.826 4.57105 16.7399 4.3768C16.3045 4.17695 15.8221 4.0625 15.3125 4.0625C15.1398 4.0625 15 4.20242 15 4.375C15 4.54758 15.1398 4.6875 15.3125 4.6875C15.8909 4.6875 16.4288 4.86332 16.8763 5.16383C16.8726 5.16137 16.8695 5.15836 16.8658 5.15594Z" />
		// <path d="M17.4926 15.1711C17.4764 14.9603 17.4261 14.7552 17.3396 14.5622C17.0947 14.6425 16.8339 14.6875 16.5625 14.6875C16.0321 14.6875 15.5237 14.5208 15.0921 14.2057C15.0113 14.1472 14.9078 14.1304 14.8135 14.1601C14.7186 14.1902 14.6438 14.2636 14.6118 14.3577C14.418 14.9287 13.8834 15.3125 13.2813 15.3125C13.1085 15.3125 12.9688 15.4524 12.9688 15.625C12.9688 15.7976 13.1085 15.9375 13.2813 15.9375C14.0186 15.9375 14.686 15.5394 15.0427 14.9157C15.5069 15.1762 16.0257 15.3125 16.5625 15.3125C16.8863 15.3125 17.1986 15.2629 17.4926 15.1711Z" />
		// </svg>`
		// 		}
		//     {
		//       title: "Spotify",
		//       component: SpotifyAttributes,
		//       props: {},
		//       icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//   <path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z" clip-rule="evenodd" />
		// </svg>`,
		//     },
		//     {
		//       title: "LifeStream",
		//       component: TrendChart,
		//       props: { graphName: "LifeStream" },
		//       icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//   <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
		// </svg>`,
		//     },
		//     {
		//       title: "Mood",
		//       component: Mood,
		//       props: {},
		//       icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//   <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z" clip-rule="evenodd" />
		// </svg>`,
		//     },
		// ...(!$IsNative
		//   ? [
		//       {
		//         title: "Location",
		//         component: AllLocationsMap,
		//         props: {},
		//         icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//       <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
		//       </svg>`,
		//       },
		//       {
		//         title: "Relationships",
		//         component: Relationships,
		//         props: {},
		//         icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//   <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
		// </svg>`,
		//       },
		//     ]
		//   : []),
		//   {
		//     title: "Topics",
		//     component: Topics,
		//     props: {},
		//     icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		//   <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
		//   <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
		// </svg>`,
		// },
	];

	$: startOfWeek = dayjs()
		.subtract(4, 'hour')
		.subtract(
			!dayjs().day() && selectedTimePeriod === 'week'
				? $DateRange.daysInPast + 1
				: $DateRange.daysInPast,
			'day'
		)
		.startOf(selectedTimePeriod);

	$: datesToAnalyse =
		selectedTimePeriod === 'day'
			? [dayjs().subtract(4, 'hour').subtract($DateRange.daysInPast, 'day').format('YYYY-MM-DD')]
			: Array(selectedTimePeriod === 'week' ? 7 : startOfWeek.endOf('month').date())
					.fill()
					.map((_, i) =>
						startOfWeek.add(selectedTimePeriod === 'week' ? i + 1 : i, 'day').format('YYYY-MM-DD')
					);
	setInterval(() => {
		if (
			selectedTimePeriod === 'day' &&
			!$DateRange.daysInPast &&
			datesToAnalyse[0] !== dayjs().subtract(4, 'hour').format('YYYY-MM-DD')
		) {
			shiftPeriods(0 - $DateRange.daysInPast);
			datesToAnalyse =
				selectedTimePeriod === 'day'
					? [
							dayjs()
								.subtract(4, 'hour')
								.subtract($DateRange.daysInPast, 'day')
								.format('YYYY-MM-DD')
					  ]
					: Array(selectedTimePeriod === 'week' ? 7 : startOfWeek.endOf('month').date())
							.fill()
							.map((_, i) =>
								startOfWeek
									.add(selectedTimePeriod === 'week' ? i + 1 : i, 'day')
									.format('YYYY-MM-DD')
							);
		}
	}, 1000 * 60);

	let showAssistant;
	let assistantResponse;
	let assistantInput;
	let userQuery;
	$: postPrompt =
		`\n\nHere's the data you should use to answer the question:\n` +
		JSON.stringify(formatProductivityData($TodaysData?.activitywatch?.productivityMetrics || {}));
	let handleAssistantInput = (e) => {
		if (prompt && e.key === 'Enter') {
			let input = assistantInput + postPrompt;
			console.log(input);
			userQuery = assistantInput;
			assistantInput = '';
			chat(input).then((res) => {
				console.log(res);
				assistantResponse = res?.content;
				showAssistant = true;
			});
		}
	};
	function formatProductivityData(data) {
		data =
			data?.contexts?.map((c) => {
				delete c.reason;
				return c;
			}) || {};
		data = { ...data, options: null, previous: null };
		return data;
	}
</script>

<!-- {#if $IsNative}
  <div
    class="flex items-center justify-around text-sm font-semibold p-2 border-b border-gray-800">
    <div on:click={() => (show = 0)}>OVERVIEW</div>
    <div on:click={() => (show = 1)}>INSIGHTS</div>
    <div on:click={() => (show = 2)}>GRAPHS</div>
  </div>
{/if} -->
<section
	class="grid min-h-full w-full lg:grid-cols-3"
	id={showWhenPathIs.slice(1)}
	style="transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 550ms; transition-delay:100ms; max-width:100vw;opacity:1;{$Path ===
	showWhenPathIs
		? 'position:relative;'
		: 'opacity:0;position:absolute;bottom:100%;pointer-events:none;max-width:100vw;z-index:0;'}"
>
	<!-- .slice(show * 2, $IsNative ? (show + 1) * 2 : components?.length) -->
	<div
		class="h-screen relative flex gap-4 flex-col hover:z-20 items-stretch {contentSlot === false
			? 'z-0 opacity-0 pointer-events-none'
			: ''}"
		style="
	left: 0px;
	top: 0px;
	padding-top: calc(4rem + env(safe-area-inset-top, 0));
	border-radius: 0px 40px 40px 0px;"
	>
		<Navigation show={$Path === showWhenPathIs} />
		{#if contentSlot}
			<div class="px-6 h-5/6">
				<slot name="content" />
			</div>
		{:else}
			<!-- <div class="text-2xl">Good {partOfDay}</div>
				<div class="text-lg opacity-90">{dayjs().subtract(4, 'hour').format('ddd D MMM')}</div>
				<div class="text-2xl">
					<span class="text-2xl font-semibold">Michael</span>
				</div>
				<div class="h-[1px] w-[40%] bg-gray-800 my-2" /> -->
			<div class="absolute w-max flex items-center centreInContext top-11 z-30">
				<div class="absolute centreInContext text-xs -top-2 opacity-60 font-medium">
					{#if selectedTimePeriod === 'month' || selectedTimePeriod === 'week'}
						{startOfWeek.add(selectedTimePeriod === 'week' ? 1 : 0, 'day').format('ddd D MMM')}
					{:else}
						<DatePicker
							date={$DateRange.end?.subtract(5, 'hour').format()}
							onClose={(date) => {
								if (
									$DateRange.end.format() !==
									dayjs(date).endOf('day').add(4, 'hour').hour(4).startOf('hour').format()
								) {
									let daysToShift =
										dayjs().subtract(4, 'hour').diff(dayjs(date), 'day') - $DateRange.daysInPast;
									console.log({ daysToShift });
									shiftPeriods(daysToShift);
								}
							}}
						/>
					{/if}
				</div>
				{#if $DateRange.daysInPast >= 2 && $DateRange.daysInPast > (selectedTimePeriod === 'day' ? 1 : selectedTimePeriod === 'week' ? 7 : 28)}
					<button
						class="p-0.5 pl-2.5 bg-gray-900 border-solid border-gray-800 border-2 border-opacity-20 disabled:bg-gray-800 disabled:bg-opacity-0 flex -gap-2 justify-center items-center rounded-full rounded-l-none absolute left-full centreInContext z-0"
						style="height:min-content;line-height:0.5"
						on:click={() => shiftPeriods(-$DateRange.daysInPast, 'day')}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4"
							style="margin-right:-0.55rem"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fill-rule="evenodd"
								d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
								clip-rule="evenodd"
							/>
						</svg>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-4 w-4"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fill-rule="evenodd"
								d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
								clip-rule="evenodd"
							/>
						</svg>
					</button>
				{/if}
				<button
					class="p-0.5 pr-2.5 bg-gray-900 border-solid border-gray-800 border-2 border-opacity-50 flex justify-center items-center rounded-full rounded-r-none relative left-2 z-0"
					style="height:min-content;line-height:0.5"
					on:click={() => shiftPeriods(1)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-4 w-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
							clip-rule="evenodd"
						/>
					</svg>
				</button>
				<div
					class="flex relative z-10 focus:outline-none uppercase focus:shadow-outline bg-gray-900 border-solid border-gray-800 border-2 border-opacity-50 rounded-full"
				>
					{#each ['day', 'week', 'month'] as period}
						<button
							class="px-3 py-1 text-xs font-medium capitalize {selectedTimePeriod === period
								? 'bg-gray-800 text-white'
								: 'bg-transparent text-gray-300 opacity-40 hover:opacity-80'}"
							on:click={() => {
								if (selectedTimePeriod === period) return;
								let prevPeriod = selectedTimePeriod;
								selectedTimePeriod = period;
								if (period === 'month') {
									shiftPeriods(
										prevPeriodsInPast === 0 ? -$DateRange.daysInPast : 0,
										prevPeriodsInPast === 0 ? 'day' : undefined
									);
								} else if (period === 'week') {
									shiftPeriods(
										prevPeriodsInPast === 0 && mostRecent['week'] === 0
											? -$DateRange.daysInPast
											: 0,
										prevPeriodsInPast === 0 && mostRecent['week'] === 0 ? 'day' : undefined,
										prevPeriod === 'month' &&
											mostRecent['week'] < $DateRange.daysInPast &&
											$DateRange.daysInPast - mostRecent['week'] < 28
											? mostRecent['week']
											: undefined
									);
								} else
									shiftPeriods(
										prevPeriodsInPast === 0 && mostRecent['day'] === 0
											? -$DateRange.daysInPast
											: prevPeriod === 'week'
											  ? -1
											  : 0,
										undefined,
										mostRecent['day'] < $DateRange.daysInPast &&
											$DateRange.daysInPast - mostRecent['day'] < (prevPeriod === 'week' ? 7 : 28)
											? mostRecent['day']
											: undefined
									);
							}}
						>
							<span style="min-width:5ch;display:block;">
								{period}
							</span>
						</button>
					{/each}
				</div>
				<button
					class="p-0.5 pl-2.5 bg-gray-900 border-solid border-gray-800 border-2 border-opacity-50 disabled:bg-gray-800 disabled:bg-opacity-20 flex justify-center items-center rounded-full rounded-l-none relative right-2 z-0"
					style="height:min-content;line-height:0.5"
					on:click={() => {
						console.log($DateRange);
						shiftPeriods(0 - 1);
					}}
					disabled={$DateRange.daysInPast <
						(selectedTimePeriod === 'day' ? 1 : selectedTimePeriod === 'week' ? 7 : 28)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-4 w-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
							clip-rule="evenodd"
						/>
					</svg>
				</button>
			</div>
			<Feed {datesToAnalyse} />

			<!-- <TimeTracked show={('time', 'flow')} /> -->
		{/if}

		<NavColumn
			style={'width:calc(max(33.3%, min(420px, 100vw)) - 3rem);position:relative;margin-top:auto;margin-bottom:2rem;'}
		/>
	</div>
	{#each components as { title, component, icon, props }, i}
		{#if component && (i < 2 || $RenderDelayedContent)}
			<div
				style="background:linear-gradient(90deg, #060f1f, #316FE212) ;border-radius:2rem;"
				class="hidden {shifting
					? 'pointer-events-none animate-pulse'
					: ''} max-h-screen h-screen overflow-scroll rounded-2xl sm:block relative sm:col-span-2 {contentSlot ===
				false
					? 'left-0 w-full z-10'
					: ''} pt-4 px-6"
			>
				<!-- </div>
			<div
				class=" mb-32 component z-0 fixed left-1/3 w-1/3 top-36 px-6  flex flex-col justify-stretch p-1 {title ==
				'LifeStream'
					? 'col-span-3'
					: ''}"
				style="min-height:fit-content;margin-top:1vh;"
			> -->
				{#if title !== 'Recent'}
					<div class="flex py-3 gap-2 items-center">
						{@html icon}
						<div class="uppercase text-sm font-medium">{title}</div>
					</div>
				{/if}
				<div
					class="w-full h-full flex flex-col items-stretch justify-between"
					style="min-height:350px;"
				>
					{#if showcaseSlot}
						<slot name="showcase" />
					{:else}
						<RecentSummary {datesToAnalyse} {selectedTimePeriod} />
					{/if}
				</div>
				{#if $Path === '/' && showWhenPathIs === '/' && $IsNative}
					<div
						style="margin-top: env(safe-area-inset-top);"
						class="absolute centreInContext top-8 z-50 flex justify-center items-center px-2 {shifting
							? 'pointer-events-none brightness-150 animate-pulse'
							: ''}"
					>
						{#if $DateRange.daysInPast >= 2 && $DateRange.daysInPast >= (selectedTimePeriod === 'day' ? 1 : selectedTimePeriod === 'week' ? 7 : 28)}
							<button
								class="p-1 pl-2.5 bg-gray-800 bg-opacity-0 border-solid border-gray-800 border-2 border-opacity-20 disabled:bg-gray-800 disabled:bg-opacity-0 flex justify-center items-center rounded-full rounded-l-none absolute left-full centreInContext z-0"
								style="height:min-content;line-height:0.5"
								on:click={() => shiftPeriods(0 - $DateRange.daysInPast)}
								disabled={!$DateRange.daysInPast}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
										clip-rule="evenodd"
									/>
								</svg>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
										clip-rule="evenodd"
									/>
								</svg>
							</button>
						{/if}
						<button
							class="p-1 pr-2.5 bg-gray-800 bg-opacity-30 border-solid border-gray-800 border-2 border-opacity-50 flex justify-center items-center rounded-full rounded-r-none relative left-2 z-0"
							style="height:min-content;line-height:0.5"
							on:click={() => shiftPeriods(1)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-4 w-4"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>

						<DatePicker
							date={$DateRange.end?.subtract(5, 'hour').format()}
							onClose={(date) => {
								if (
									$DateRange.end.format() !==
									dayjs(date).endOf('day').add(4, 'hour').hour(4).startOf('hour').format()
								) {
									let daysToShift =
										dayjs().subtract(4, 'hour').diff(dayjs(date), 'day') - $DateRange.daysInPast;
									console.log({ daysToShift });
									shiftPeriods(daysToShift);
								}
							}}
						/>
						<button
							class="p-1 pl-2.5 bg-gray-800 bg-opacity-30 border-solid border-gray-800 border-2 border-opacity-50 disabled:bg-gray-800 disabled:bg-opacity-20 flex justify-center items-center rounded-full rounded-l-none relative right-2 z-0"
							style="height:min-content;line-height:0.5"
							on:click={() => shiftPeriods(0 - 1)}
							disabled={!$DateRange.daysInPast}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-4 w-4"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>
					</div>
					<!-- {#if $IsElectron}
		<Switch bind:checked={$UserInfo.pauseScreenTime}><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			<path stroke-linecap="round" stroke-linejoin="round" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
		  </svg></Switch>
		{:else} -->

					<!-- {/if} -->
				{/if}
				<!-- {#if shifting}
					<LoadingOrb fullScreen={false} />
				{/if} -->
			</div>
		{/if}
	{/each}
	<!-- <button on:click={() => (showAssistant = true)} class="fixed bottom-4 right-4"> Chat </button> -->
</section>
<!-- {#if showAssistant}
	<Modal
		options={{
			seeThrough: true,
			topLevel: true,
			fullScreen: false,
			cssText: 'left:unset;top:unset;transform:unset;',
			classes: 'bottom-10 right-4 w-96',
			name: 'chat' + Math.round(Math.random() * 10000)
		}}
		bind:show={showAssistant}
	>
		<Playground {postPrompt} />
		<div use:autoAnimate>
		<input
			class="mb-2 border border-gray-500 w-full"
			placeholder="Ask me anything"
			bind:value={assistantInput}
			on:keydown={handleAssistantInput}
		/>
		{#if userQuery}
			<p class="font-semibold">{userQuery}</p>
		{/if}
		{#if assistantResponse}
			<p class="font-medium text-sm">{assistantResponse}</p>
		{/if}
	</div> 
 </Modal>
{/if} -->
{#if ['/social', '/'].some((p) => $Path == [])}
	<Paywall subscribeText={$Path.includes('time') ? ' for time reports' : 'for the full app'}>
		<div class="text-sm text-center font-medium h-0">
			You've done <span class="font-bold"
				>{makeDecimalIntoTime(
					$TodaysData?.activitywatch?.productivityMetrics?.deepWork / 3600,
					'durationNoZero'
				)}</span
			> deep work today.
		</div>
	</Paywall>
{/if}

<style>
	@media (prefers-color-scheme: dark) {
		.hidden.rounded-2xl {
			background: linear-gradient(90deg, #101527, #316fe212) !important;
		}
	}
</style>
