<script>
	import {
		Path,
		ShowDetailsFor,
		IsDev,
		IsNative,
		IsElectron,
		Tracking,
		TodaysData,
		UserInfo,
		Platform
	} from '$lib/store.js';
	import Switch from './Switch.svelte';
	import Modal from './Modal.svelte';
	import Copyable from './Copyable.svelte';
	import { postUserInfo } from '$lib/utils.js';
	import { sync } from '$lib/connectUtils';
	import { onMount } from 'svelte';
	import Connect from './connect/Connect.svelte';
	import dayjs from 'dayjs';
	let posted = $UserInfo.screenTimeLocalOnly;
	let postedalwaysDark;
	$: if ($UserInfo.screenTimeLocalOnly === undefined && $UserInfo.syncActivityWatchEnabled)
		$UserInfo.screenTimeLocalOnly = true;
	$: if ($UserInfo.screenTimeLocalOnly !== undefined && $UserInfo.screenTimeLocalOnly !== posted) {
		posted = $UserInfo.screenTimeLocalOnly;
		postUserInfo() || console.log('postedScreentimeLocalOnly', { posted, $UserInfo });
	}
	$: if ($UserInfo.alwaysDark !== undefined && $UserInfo.alwaysDark !== postedalwaysDark) {
		if (typeof document !== 'undefined') {
			if ($UserInfo.alwaysDark) {
				document.documentElement.classList.add('alwaysDark');
			} else {
				document.documentElement.classList.remove('alwaysDark');
			}
		}
		postedalwaysDark = $UserInfo.alwaysDark;
		postUserInfo() || console.log('postedalwaysDark', { posted, $UserInfo });
	}
	let pauseTrackingFor = 10;
	export let privacyOnly = false;
	let showApi;
</script>

<div
	class="flex justify-between items-center p-4 bg-gray-800 bg-opacity-30 border border-gray-800 rounded-2xl my-4"
>
	<span class="font-semibold">Keep all data local-only:</span>
	<Switch
		options={{
			direction: 'row',
			id: 'screenTimeLocalOnly'
		}}
		bind:checked={$UserInfo.screenTimeLocalOnly}
	/>
</div>
<div
	class="flex justify-between items-center p-4 bg-gray-800 bg-opacity-30 border border-gray-800 rounded-2xl my-4"
>
	<span class="font-semibold">Permanent dark mode: </span>
	<Switch
		options={{
			direction: 'row',
			id: 'alwaysDark'
		}}
		on:click={() => console.log('alwaysdark checkbox')}
		bind:checked={$UserInfo.alwaysDark}
	/>
</div>
{#if !privacyOnly}
	<Connect sources={[]} />
	<div
		class="wasflex hidden justify-between items-center p-4 gap-4 bg-gray-800 bg-opacity-30 border border-gray-800 rounded-2xl"
	>
		<span class="font-semibold">Pause tracking for: </span>
		<div>
			<input type="number" class="text-center bg-gray-700 w-12" bind:value={pauseTrackingFor} /> mins
		</div>
		<button
			class="text-white flex items-center p-2 gap-2 px-3"
			on:click={() => {
				if ($Tracking.watcherStatus === 'paused') {
					window.api?.call('runWatcher');
				} else {
					window.api?.call('pauseScreenTime', pauseTrackingFor * 60 * 1000);
					$Tracking.watcherStatus = 'paused';
					setTimeout(
						() => {
							$Tracking.watcherStatus = 'running';
						},
						pauseTrackingFor * 60 * 1000
					);
				}
			}}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-4 w-4"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
					clip-rule="evenodd"
				/>
			</svg>
			{$Tracking.watcherStatus === 'paused' ? 'Run' : 'Pause'}
		</button>
	</div>

	<div
		class="flex flex-col items-center p-4 gap-4 bg-gray-800 bg-opacity-30 border border-gray-800 rounded-2xl my-4 text-sm"
	>
		{#if $Platform === 'macos'}
			<button
				class="w-full"
				on:click={() => {
					window.api?.call($UserInfo.hideAppFromDock ? 'showDock' : 'hideDock');
					$UserInfo.hideAppFromDock = !$UserInfo.hideAppFromDock;
					postUserInfo();
				}}
			>
				{$UserInfo.hideAppFromDock ? 'Show' : 'Hide'} app {$UserInfo.hideAppFromDock
					? 'in'
					: 'from'} Dock
			</button>
		{/if}
		<button
			class="w-full"
			on:click={(event) => {
				sync('activitywatch', { hardResync: true });
				event.target.innerText = 'Syncing...';
				event.target.disabled = true;
				setTimeout(() => {
					event.target.innerText = 'Resync Historical Screentime';
					event.target.disabled = false;
				}, 10000);
			}}
		>
			Resync Historical Screentime
		</button>
		<!-- <button
			class="w-full"
			on:click={() => {
				try {
					window.api?.call('toggleSessionCoaching').catch((e) => console.error(e));
				} catch (e) {
					console.error('toggleSessionCoaching', e);
				}
			}}
		>
			Turn Session coach {$UserInfo.noCoaching ? 'on' : 'off'}
		</button> -->
		<button
			class="w-full"
			on:click={(event) => {
				showApi = true;
			}}
		>
			Show API credentials
		</button>
		{#if $IsElectron}
			<button
				class="w-full"
				on:click={(event) => {
					window?.api?.call('showSessionCoach').catch((e) => console.error(e));
				}}
			>
				Show session coach
			</button>
		{/if}
		{#if $IsDev}
			<button
				class="w-full"
				on:click={(event) => {
					window.api?.call('requestFullDiskAccess');
					event.target.innerText = 'Requested...';
					event.target.disabled = true;
				}}
			>
				Request Full Disk Access
			</button>
		{/if}
	</div>
{/if}
{#if showApi}
	<Modal bind:show={showApi} options={{ topLevel: true }}>
		<div class="px-4 text-center">
			<h2 class="text-2xl font-semibold">Giving access to your data</h2>
			<div class="text-sm mb-4">
				Giving someone your refresh token allows them to access all your Magicflow data.
			</div>
			<div class="text-sm mb-4 font-bold">
				Do not share it with anyone you don't trust to have full access to the data Magicflow
				collects.
			</div>
			<Copyable textToCopy={localStorage.ethi_refresh} description="Refresh token" />
		</div>
	</Modal>
{/if}
