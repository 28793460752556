<script>
	import {
		Path,
		ShowDetailsFor,
		IsNative,
		IsElectron,
		Tracking,
		TodaysData,
		UserInfo,
		Loading,
		IsDev
	} from '$lib/store.js';
	import LoadingBar from '../components/LoadingBar.svelte';
	// import Modal from '../components/Modal.svelte';
	// import Playground from '../sections/Playground.svelte';
	import Toast from '../components/Toast.svelte';
	import Referral from '../components/Referral.svelte';
	import Controls from '../components/Controls.svelte';
	import { postUserInfo } from '$lib/utils.js';
	import { goto, afterNavigate, beforeNavigate } from '$app/navigation';
	import { sync } from '$lib/connectUtils';
	import { onMount } from 'svelte';
	import dayjs from 'dayjs';

	export let includeControls = true;

	let posted = $UserInfo.screenTimeLocalOnly;
	$: if ($UserInfo.screenTimeLocalOnly !== undefined && $UserInfo.screenTimeLocalOnly !== posted) {
		posted = $UserInfo.screenTimeLocalOnly;
		postUserInfo() && console.log('postedScreentimeLocalOnly', { posted, $UserInfo });
	}
	let pauseTrackingFor = 10;
	onMount(() => {
		if ($IsElectron) {
			window.api?.remove && window.api?.remove('isRunning');
			window.api?.receive('isRunning', () => {
				$Tracking.watcherStatus = 'running';
			});
		}
	});
	// 	<stop offset="0.14285714285714285" stop-color="#ff0073" />

	// <stop offset="0.2857142857142857" stop-color="#d246d9" />

	// <stop offset="0.42857142857142855" stop-color="#9368ff" />

	// <stop offset="0.5714285714285714" stop-color="#20a3ff" />

	// <stop offset="0.7142857142857142" stop-color="#00c0ca" />

	// <stop offset="0.8571428571428571" stop-color="#00f0aa" />

	let menuItems = [
		{
			title: 'Home',
			href: '/',
			iconPaths: [
				'M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z',
				'M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z'
			],
			gradient: `<linearGradient
						xmlns="http://www.w3.org/2000/svg"
						id="gHome"
						gradientTransform="rotate(77)"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0" stop-color="#FAFBFE" />

						

						<stop offset="1" stop-color="#B8C9EEbb" />
					</linearGradient>`,
			isCategory: false
		},
		...// IsNative && !$IsNative && IsElectron && !$IsElectron
		// ? [
		// 		{
		// 			title: 'Understand',
		// 			href: '/understand',
		// 			iconPath: `M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z`,
		// 			gradient: `<linearGradient id="gUnderstand" gradientTransform="rotate(75)">
		// 			<stop offset="20%" stop-color="#ff5160" />
		// 			<stop offset="80%" stop-color="#ff0073" />
		// 		</linearGradient>
		// 		`,
		// 			isCategory: true,
		// 			textOnHover: 'Understand - find out what companies know about you and how they use it'
		// 		},
		// 		{
		// 			title: 'Control',
		// 			href: '/control',
		// 			iconPath: `M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4`,
		// 			gradient: `<linearGradient id="gControl" gradientTransform="rotate(75)">
		// 				<stop offset="20%" stop-color="#d80061" />
		// 				<stop offset="80%" stop-color="#9641ff" />
		// 			</linearGradient>
		// 		`,
		// 			isCategory: true,
		// 			orderAfterButton: true,
		// 			textOnHover:
		// 				'Control - decide what you want Facebook and Google to keep and delete the rest'
		// 		},
		// 		{
		// 			title: 'Use',
		// 			href: '/use',
		// 			iconPath: `M13 10V3L4 14h7v7l9-11h-7z`,
		// 			gradient: `<linearGradient id="gUse" gradientTransform="rotate(75)">
		// 			<stop offset="20%" stop-color="#00c0aa" />
		// 			<stop offset="80%" stop-color="#1e90ff" />
		// 		</linearGradient>`,
		// 			isCategory: true,
		// 			orderAfterButton: true,
		// 			textOnHover:
		// 				'Use - unlock the power of your data for yourself to get personalised browsing'
		// 		}
		//   ]
		//

		[
			// {
			// 	title: $IsNative ? 'Health' : 'Time',
			// 	href: '/time',
			// 	iconPaths: [
			// 		'M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z'
			// 	],
			// 	gradient: `<linearGradient id="gTime" gradientTransform="rotate(75)">
			// 				<stop offset="20%" stop-color="#d80061" />
			// 				<stop offset="80%" stop-color="#9641ff" />
			// 			</linearGradient>`,
			// 	isCategory: true,
			// 	orderAfterButton: false,
			// 	textOnHover: "Time report - explore how you've spent your time"
			// },
			// {
			// 	title: 'Insight',
			// 	href: '/insights',
			// 	iconPaths: [
			// 		'M12 .75a8.25 8.25 0 00-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 00.577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 01-.937-.171.75.75 0 11.374-1.453 5.261 5.261 0 002.626 0 .75.75 0 11.374 1.452 6.712 6.712 0 01-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 00.577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0012 .75z',
			// 		'M9.013 19.9a.75.75 0 01.877-.597 11.319 11.319 0 004.22 0 .75.75 0 11.28 1.473 12.819 12.819 0 01-4.78 0 .75.75 0 01-.597-.876zM9.754 22.344a.75.75 0 01.824-.668 13.682 13.682 0 002.844 0 .75.75 0 11.156 1.492 15.156 15.156 0 01-3.156 0 .75.75 0 01-.668-.824z'
			// 	],
			// 	gradient: `<linearGradient id="gInsight" gradientTransform="rotate(75)">
			// 			<stop offset="20%" stop-color="#ff5160" />
			// 			<stop offset="80%" stop-color="#ff0073" />
			// 		</linearGradient>`,
			// 	isCategory: true,
			// 	orderAfterButton: true,
			// 	textOnHover:
			// 		'Insights - unlock the power of your data for yourself to get personalised insights'
			// },

			{
				title: 'Social',
				href: '/social',
				iconPaths: [
					'M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z',
					'M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z'
				],
				gradient: `<linearGradient id="gSocial" gradientTransform="rotate(75)">
						<stop offset="20%" stop-color="#00c0aa" />
						<stop offset="80%" stop-color="#1e90ff" />
					</linearGradient>`,
				isCategory: true,
				orderAfterButton: true
			}
		]
	];
	let showControls;
	const handle_keydown = (e) => {
		if (!showControls) return;
		// console.log({ e });
		if (e.key === 'Escape') {
			showControls = false;
			return;
		}
	};
	let showReferral;
	let exposure = 1;
	// style="filter:
	// 		brightness(calc(0.5 + {exposure}))
	// 		grayscale(calc(1.5 - {Math.min(1, exposure)} * 1.5))
	// 		contrast(calc(2.25 - {Math.min(1, exposure)} * 1.25));"
	export let classes = '';
	export let style = '';
	let showAI;
</script>

<svelte:window on:keydown={handle_keydown} />
<div
	style="border-radius: 1000px;
		border: none;background:linear-gradient(135deg, rgb(16, 32, 48), rgba(49, 111, 226, 0.133));{style};"
	class=" {classes} flex-shrink-0 lg:mx-auto mb-2 pointer-events-auto"
>
	{#if $IsElectron && $Path === '/' && includeControls}
		<LoadingBar />
	{/if}
	<div
		class="flex justify-around p-0 mb-0.5 relative {($IsNative || $IsElectron) &&
		$Tracking.showTimeline
			? ''
			: $Path === '/'
			  ? ''
			  : 'z-10'}"
	>
		{#each menuItems as menuItem}
			<a
				sveltekit:prerender
				href={menuItem.href}
				on:click={(e) => {
					e.preventDefault();
					if (menuItem.href === $Path) $ShowDetailsFor[$Path] = undefined;
					// else {
					goto(menuItem.href);
					$Loading = true;
					setTimeout(() => {
						$Loading = false;
					}, 300);
					// }
				}}
				class=" {(menuItem.isCategory ? $Path.startsWith(menuItem.href) : $Path === menuItem.href)
					? 'brightness-150 '
					: 'opacity-60'}
					{menuItem.orderAfterButton
					? 'order-2'
					: ''} relative sat hover:opacity-100 h-16 pb-1 w-16 flex flex-col justify-center items-center"
			>
				<svg
					class="w-6 h-6"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="url(#gHome)"
				>
					<defs>
						{@html menuItem.gradient}
					</defs>
					{#each menuItem.iconPaths as d}
						<path fill-rule="evenodd" {d} clip-rule="evenodd" />
					{/each}
				</svg>
				<div
					style="font-size:0.65rem;color:B8C9EE;"
					class="absolute bottom-2 left-0 w-full text-center capitalize"
				>
					{menuItem.title}
				</div>
				{#if menuItem.textOnHover}
					<div
						class="absolute w-96 z-50 hidden left-full top-1/2 transform
        -translate-y-1/2 bg-gray-800 bg-opacity-30 border border-gray-800 text-white text-sm p-4"
					>
						{menuItem.textOnHover}
					</div>
				{/if}
			</a>
		{/each}
		{#if $IsNative || $IsElectron || $IsDev}
			<button
				on:click={(event) => {
					console.log(event, showControls);
					if (showControls) {
						showControls = false;
						return;
					} else {
						showControls = true;
						return;
					}
					showControls = !showControls;
					// false && $IsDev ? (showAI = !showAI) : ();
				}}
				style={($IsNative || $IsElectron || $IsDev) && $Tracking.showTimeline ? 'z-index:51;' : ''}
				class="{($IsNative || $IsElectron || $IsDev) && $Tracking.showTimeline
					? 'hover:shadow-blue-900'
					: 'hover:scale-110'} relative p-0 h-12 w-12 mt-2 flex shadow transition-transform justify-center items-center rounded-full"
			>
				<!-- {#if false && $IsDev}
					<div class="canvas mix-blend-lighten overflow-hidden rounded-full">
						<video
							src="/aipulse.mp4"
							webkit-playsinline
							playsinline
							class="h-full  absolute centreInContext scale-150"
							muted="true"
							autoplay
							loop
						/>

						<img class="layer photo" src="/orb.webp" alt="orb" />
					<img
						class="layer photo s-PJn-GOT2yiWP"
						src="/orb.webp"
						alt="orb"
						style="filter:  {($IsNative || $IsElectron) && $Tracking.showTimeline
							? ''
							: 'invert(1) hue-rotate(180deg)'} brightness(1.5) saturate(1.5);mix-blend-mode: lighten;"
					/>
					</div>
				{:else} -->
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="url(#gControlCenter)"
					class="w-6 h-6"
				>
					<path
						d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
					/>
					<defs>
						<linearGradient
							xmlns="http://www.w3.org/2000/svg"
							id="gControlCenter"
							gradientTransform="rotate(77)"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0" stop-color="#FAFBFE" />
							<stop offset="1" stop-color="#B8C9EEbb" />
						</linearGradient>
					</defs>
				</svg>
				<!-- {/if} -->
				<!-- {#if $Tracking.showTimeline}
				<span style={$Tracking.showTimeline ? 'transform: rotate(90deg);' : ''} />
				<span
					style={$Tracking.showTimeline ? 'transform: rotate(90deg);left: 50%;right: 50%;' : ''}
				/>
			{:else}
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-8 w-8 absolute centreInContext"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fill-rule="evenodd"
						d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
						clip-rule="evenodd"
					/>
				</svg>
			{/if} -->
			</button>
		{/if}
	</div>
	<!-- {#if showAI}
		<Modal
			bind:show={showAI}
			absolute={'true'}
			options={{
				cssText:
					'height:600px;bottom:100%;top:unset;left:unset;transform:unset;width:100%;max-width:unset;padding:0;'
			}}
		>
			<Playground />
		</Modal>
	{/if} -->
</div>
{#if ($IsElectron && includeControls) || $IsDev}
	<!-- <div class="h-3" /> -->
	<div
		class=" fixed w-full z-50 left-0 bottom-0 p-0.5 px-2 {showControls
			? 'rounded-2xl'
			: ''} text-gray-300 uppercase text-xs font-semibold"
	>
		<Toast bind:showToast={showControls}>
			<div class="text-sm">
				<div class="uppercase h-4 text-center -mt-4 font-semibold">Control Center</div>
				<Controls />
			</div>
		</Toast>

		<div
			on:click={() => (showControls = !showControls)}
			class="cursor-pointer relative flex justify-between items-center w-full"
		>
			<div class="flex items-center gap-2 cursor-pointer">
				{#if $Tracking.watcherStatus === 'paused'}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-4 w-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
							clip-rule="evenodd"
						/>
					</svg>
					Paused
				{:else if $Tracking.watcherStatus === 'running'}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-4 w-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
							clip-rule="evenodd"
						/>
					</svg>
					Running
				{:else}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-4 w-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fill-rule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
							clip-rule="evenodd"
						/>
					</svg>
					{$Tracking.watcherStatus}
				{/if}
			</div>
			<div
				on:click|stopPropagation={() => (showReferral = true)}
				class="absolute centreInContext h-full px-4 rounded-lg text-center hover:bg-gray-800"
				style="color:white;background: linear-gradient(119deg, #00c4ff,  #fb00ff);background-size:cover;background-clip:text;-webkit-background-clip:text;"
			>
				{$IsDev ? 'DEV' : 'Refer a friend to get 1 month free!'}
			</div>

			<div
				class="flex items-center gap-2 cursor-pointer"
				on:click|stopPropagation={() => window.location.reload()}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-4 w-4"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fill-rule="evenodd"
						d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
						clip-rule="evenodd"
					/>
				</svg>
				{$TodaysData?.lastUpdated ? dayjs($TodaysData?.lastUpdated).format('HH:mm') : '--:--'}
			</div>
		</div>
	</div>
{/if}

<Toast bind:showToast={showReferral}>
	<div class="text-center">
		<Referral />
	</div>
</Toast>

<style>
	a:hover > div.absolute.hidden {
		display: none;
	}
	/* @media (max-width: 768px) {
		a:hover > div.absolute.hidden {
			display: none;
		}
	} */
	/* div.flex-col::before {
			border-radius: 50% 50% 0 0;
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			background-image: url(/bg/bg-blue-blur.webp);
			background-size: cover;
			background-position: center;
			filter: blur(10px);
		} */
	/* #upload {
		order: 1;
		position: relative;
	} */

	/* boooop */
	/* button {
  color: #ecf0f1;
  background: #e74c3c;
  width: 50px;
  height: 50px;
  border: 0;
  font-size: 1.5em;
  position: relative;
} */

	/* button span {
		position: absolute;
		transition: 300ms;
		background: white;
		border-radius: 2px;
	}

	/* Create the "+" shape by positioning the spans absolutely */

	/* button span:first-child {
		top: 25%;
		bottom: 25%;
		width: 10%;
		left: 45%;
	}

	button span:last-child {
		left: 25%;
		right: 25%;
		height: 10%;
		top: 45%;
	} */
	.canvas {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 1000px;
	}

	.layer {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.photo {
		width: 100%;
		height: 100%;
	}
	/* 
	.exposure-layer {
		width: 100%;
		height: 100%;
		background: white;
		border-radius: 1000px;
		mix-blend-mode: overlay;
	} */
</style>
