<script>
	import { Loading } from '$lib/store.js';
	import { tweened } from 'svelte/motion';
	import { cubicOut } from 'svelte/easing';
	let value = 0.01;
	const progress = tweened(0.01, {
		duration: (from, to) => (to < from ? 0 : 1250),
		easing: cubicOut
	});
	$: if ($Loading) {
		progress.set(0.01);
		progress.set(0.3333);
		value = 0.3333;
	}
	setInterval(() => {
		value = $Loading ? (value + 0.3333) % 1.3333 : 0.9999;
		progress.set(value);
		if (value === 0.01) {
			value = (value + 0.3333) % 1.3332;
			progress.set(value);
		}
	}, 1200);
</script>

<div class="absolute centreInContext h-full w-full rounded-full overflow-hidden">
	<div
		class="h-full opacity-10 bg-gradient-to-br from-blue-800 via-blue-500 to-blue-100"
		style="width:{$progress * 100}%;z-index:-1;"
	/>
</div>
