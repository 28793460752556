<script>
	import { sleep, aggregateAndSort } from '$lib/utils.js';
	import { makeDecimalIntoTime, mergeEventsCloserThan } from '$lib/connectUtils.js';
	import {
		CurrentActivity,
		LiveConnections,
		Activities,
		UserInfo,
		Version,
		IsDev,
		Data,
		Promises,
		DateRange,
		Tracking,
		TodaysData
	} from '$lib/store.js';
	import Activity from './Activity.svelte';
	import { insightAttributes } from '$lib/attributes.js';
	import Icon from './Icon.svelte';
	import Favicon from './Favicon.svelte';

	import { oscarsHealth } from '$lib/oscarsHealth.js';
	import { onMount } from 'svelte';
	import dayjs from 'dayjs';
	export let power = undefined;
	export let dataIndex = undefined;
	export let powerPeaks = [];
	export let powerDips = [];
	export let todaysData = undefined;
	export let todaysDate = undefined;
	export let sleep_debt = undefined;
	$: latestIsWork = $Tracking.latestIsWork;
	export let total_time = {};
	export let wakeup = dayjs().hour(8).startOf('hour');

	$: timeSinceWake = dayjs().hour() - wakeup.hour();
	$: ratio =
		total_time.productiveLastHalfHour /
		(total_time.productiveLastHalfHour + total_time.unproductiveLastHalfHour);
	let attributes = insightAttributes;
	$: ethiCache = $TodaysData?.ethi || [];
	$: appleToday =
		// oscarsHealth ||
		$TodaysData?.apple || {};
	$: workouts = [...(appleToday?.workoutType?.resultData || [])].map((a) => {
		return {
			...(a || []),
			duration: a.duration * 3600,
			startDate: dayjs().subtract(1, 'hour').toDate(),
			endDate: dayjs().toDate(),
			name: 'Workout',
			attributes: [a.workoutActivityName.toLowerCase()]
		};
	});
	$: walks = [
		...mergeEventsCloserThan(
			5 * 60,
			appleToday?.stepCount?.resultData
				?.filter(
					(w) =>
						dayjs(w.startDate) > dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour')
				)
				.map((walk) => {
					return {
						...(walk || []),
						duration: walk.duration * 3600,
						startDate: dayjs(walk.startDate).toDate(),
						endDate: dayjs(walk.endDate).toDate(),
						name: 'Walk',
						attributes: ['walk']
					};
				}) || []
		)
	];
	$: calendarEvents = ($LiveConnections.google || []).map((e) => ({
		...(e || []),
		duration: dayjs(e.end.dateTime).diff(dayjs(e.start.dateTime), 'second'),
		startDate: dayjs(e.start.dateTime).toDate(),
		endDate: dayjs(e.end.dateTime).toDate(),
		name:
			e.summary +
			(e.attendees
				? ' with ' +
				  (e.attendees?.[0]?.displayName ||
						e.attendees?.[0]?.email?.[0].toUpperCase() +
							e.attendees?.[0]?.email?.split('@')?.[0]?.slice(1))
				: ''),
		attributes: [e.kind?.replace('#', ' ')]
	}));

	$: activeEventsToday = $TodaysData?.activitywatch?.window?.sessions || [];
	$: activeEvents = mergeEventsCloserThan(
		2.5 * 60,
		[...(activeEventsToday || [])].map((event) => {
			return {
				...(event || []),
				attributes: ['work'],
				name: 'Work'
			};
		})
	);
	// .filter((a) => a.duration > 5 * 60);
	$: if (activeEvents[activeEvents?.length - 1])
		wakeup =
			wakeup < activeEvents[activeEvents?.length - 1].timestamp
				? wakeup
				: dayjs(activeEvents[activeEvents?.length - 1].timestamp);
	// $: console.log({ workouts, activeEvents, wakeup, activeEventsToday });
	$: $Activities = [
		// {
		// 	name: 'sleep',
		// 	attributes: ['sleep'],
		// 	startDate: dayjs().hour(4).startOf('hour').startOf('hour'),
		// 	duration: wakeup.diff(dayjs().hour(4).startOf('hour').startOf('hour'), 'second'),
		// 	endDate: wakeup
		// },
		...(calendarEvents || []),
		...(activeEvents || []),
		...(walks || []),
		...ethiCache?.filter((log) => log.name === 'Activity'),
		...workouts
	].sort(
		(a, b) =>
			dayjs(a.startedAt || a.startDate || a.timestamp) -
			dayjs(b.startedAt || b.startDate || b.timestamp)
	);
	// $: console.log({ activities: $Activities });
	let steps = todaysData?.steps?.value || 0;
	let strain;
	let timePeriods = [
		{
			timeDescriptor: 'Right now',
			increases: [
				{
					name: 'Activity',
					operator: 'Listen to ',
					attributes: ['music'],
					link: 'while you work!',
					p: 0.1,
					value: 0.2,
					comparisonValues: ['+5%', '2 hr'],
					description: `Listening to focus music <a href="https://www.semanticscholar.org/paper/Effects-of-Three-Genres-of-Focus%C2%A0Music-on-Heart-and-Kirk-Ngnoumen/19709fa2bc55a2a0f97c53e18f1551424a02b4e9">improved performance</a> on sustained attention tasks.`
				},
				{
					name: 'Activity',
					operator: 'Start a ',
					attributes: ['work'],
					link: 'timer!',
					idealDuration: 25 * 60,
					p: 0.1,
					value: 0.2,
					comparisonValues: ['+7%', '1 hr'],
					description: `Getting active <a href="https://pubmed.ncbi.nlm.nih.gov/26958898/">significantly improves cognitive performance</a>, especially if in nature!`
				},
				{
					name: 'Activity',
					operator: 'Go for a ',
					attributes: ['walk'],
					link: ' outside',
					idealDuration: 15 * 60,
					p: 0.1,
					value: 0.2,
					comparisonValues: ['+7%', '2 hrs'],
					description: `Getting active <a href="https://pubmed.ncbi.nlm.nih.gov/26958898/">significantly improves cognitive performance</a>, especially if in nature!`
				},
				{
					name: 'Activity',
					operator: 'Hot shower before ',
					attributes: ['sleep'],
					link: '',
					p: 0.1,
					value: 0.2,
					comparisonValues: ['+5%', '2 hr'],
					description: `Listening to focus music <a href="https://www.semanticscholar.org/paper/Effects-of-Three-Genres-of-Focus%C2%A0Music-on-Heart-and-Kirk-Ngnoumen/19709fa2bc55a2a0f97c53e18f1551424a02b4e9">improved performance</a> on sustained attention tasks.`
				},
				{
					name: 'Activity',
					operator: 'Do a high-intensity ',
					attributes: ['workout'],
					link: 'for at least 30 minutes!',
					idealDuration: 30 * 60,
					p: 0.1,
					value: 0.2,
					comparisonValues: ['+8%', '7 hrs'],
					description: `Getting active <a href="https://pubmed.ncbi.nlm.nih.gov/26958898/">significantly improves cognitive performance</a>, especially if in nature!`
				},
				{
					name: 'Activity',
					operator: 'Take a short ',
					attributes: ['nap'],
					link: 'in the afternoon!',
					p: 0.1,
					value: 0.2,
					comparisonValues: ['+9%', '4 hr'],
					description: `Afternoon naps <a href="https://www.tandfonline.com/doi/abs/10.1080/02640410701244983">improves alertness, memory and accuracy</a>, especially if you're underslept.`
				},
				{
					name: 'Activity',
					operator: 'Wind down for ',
					attributes: ['sleep duration'],
					link: '',
					idealDuration: 60 * 60,
					p: 0.1,
					value: 0.2,
					comparisonValues: ['+7%', '2 hrs'],
					description: `Getting active <a href="https://pubmed.ncbi.nlm.nih.gov/26958898/">significantly improves cognitive performance</a>, especially if in nature!`
				}
			]
		}
	];
	$: situations = [
		[
			'power',
			[
				{
					title: 'peak',
					condition: () =>
						powerPeaks.some((peak) => Math.abs(dayjs().diff(dayjs(peak.timestamp), 'minute')) < 90)
				},
				{
					title: 'power dip',
					condition: () =>
						powerDips.some((dip) => Math.abs(dayjs().diff(dayjs(dip.timestamp), 'minute')) < 90)
				},
				{
					title: 'power ⬆',
					condition: () =>
						power && power[dataIndex]?.value <= ((power && power[dataIndex + 1]) || {})?.value
				},
				{
					title: 'power ⬇',
					condition: () =>
						power && power[dataIndex]?.value >= ((power && power[dataIndex + 1]) || {})?.value
				}
			]
		],
		[
			'work',
			[
				{ title: 'in the zone', condition: () => ratio > 0.9 },
				{ title: 'working', condition: () => ratio > 0.75 },
				{ title: 'unfocused', condition: () => ratio > 0.6 },
				{ title: 'distracted', condition: () => ratio <= 0.6 },
				{ title: 'break', condition: () => !ratio }
			]
		],
		[
			'exercise',
			[
				{ title: 'crushing it', condition: () => steps > 900 * timeSinceWake },
				{ title: 'great', condition: () => steps > 700 * timeSinceWake },
				{ title: 'solid', condition: () => steps > 500 * timeSinceWake },
				{ title: 'light', condition: () => steps >= 300 * timeSinceWake },
				{ title: 'inactive', condition: () => steps < 300 * timeSinceWake }
			]
		],
		[
			'sleep',
			[
				{ title: 'well slept', condition: () => sleep_debt < 1.5 },
				{ title: 'adequate', condition: () => sleep_debt < 4 },
				{ title: 'underslept', condition: () => sleep_debt >= 4 }
			]
		]
	];
	let recommendations = [];
	// 			power peak
	//     - peak
	//         - work
	//     - dip
	//         - exercise if haven't, walk or stretch otherwise/sleep if evening
	//     - neutral
	//         - whatever else
	// - exercise
	//     - on track
	//     - hit daily goal
	//     - below average
	// - sleep
	//     - well slept
	//     - adequate
	//     - underslept
	// - work
	//     - in the zone
	//     - unfocused
	//     - distracted
	// }
	// 	]
	//   <h3>For the next hour:</h3>
	// <InsightCard />
	// body: stretch, take a walk outside, do some pressups
	// environment: listen to music
	// mind: breathe deeply
	// <button>See more</button>
	// <h3>For the rest of the day:</h3>
	// body: work out
	// nutrition: eat well
	// mind: meditate
	// <button>See more</button>
	// <h3>For the next few days</h3>
	// mind: sleep well
	// nutrition: eat well
	// body: exercise
	// <button>See more</button>
	let timePeriod = 0;
	$: period = timePeriods[timePeriod];
	let selectedIncrease = 1;
	$: increase = period.increases[selectedIncrease];
	$: color =
		(showActivity || increase) &&
		attributes &&
		(showActivity || increase).attributes &&
		attributes[(showActivity || increase).attributes?.[0]] &&
		attributes[(showActivity || increase).attributes?.[0]].color;

	let showActivity;
	$: currentSituations = situations.map(([category, situations]) => {
		let title = (situations.find((situation) => situation.condition()) || { title: '--' }).title;
		return [category, title];
	});
	let selectedSituation;

	let summary;
	$: activitywatchToday =
		$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
			dayjs().subtract(4, 'hour').format('YYYY-MM-DD')
		] || activitywatchToday;
	$: categoriesInSession = [...(activitywatchToday?.productivityLastHour?.events || [])]
		?.sort((a, b) => b.duration - a.duration)
		.filter((c, i, a) => c?.categories?.[0] !== 'Untracked');
	// $: summary = categoriesInSession?.[0]?.categories?.[0];
	$: latestContext = activitywatchToday?.activitywatch?.productivityLastHour?.contexts
		?.filter((s) => s?.duration > 30)
		?.sort(
			(a, b) =>
				dayjs(typeof a.timestamp == 'string' ? a.timestamp.replace('Z', '') : a.timestamp) -
				dayjs(typeof b.timestamp == 'string' ? b.timestamp.replace('Z', '') : b.timestamp)
		)[
		activitywatchToday?.activitywatch?.productivityLastHour?.contexts?.filter(
			(s) => s?.duration > 30
		)?.length - 1
	];
	// $: console.log(
	// 	activitywatchToday?.productivityLastHour,
	// 	activitywatchToday?.productivityLastHour?.events?.length
	// );
	$: inDeepWork = latestContext?.duration > 300 && latestContext?.focus;
	let latestSent;
	let posted;
	onMount(() => {
		if (!$Version?.platform === 'darwin' || !$UserInfo?.autoFocus) return;
		if (window.deepWorkInterval) clearInterval(window.deepWorkInterval);

		window.deepWorkInterval = setInterval(() => {
			console.log({ latestContext, inDeepWork });
			let slackStatus = {
				status_text: 'Deep Work',
				status_emoji: ':technologist:',
				status_expiration: dayjs().add(60, 'minute').unix()
			};
			if (!posted && $UserInfo.slackToken) {
				posted = true;
				fetch('https://cors.magicflow.workers.dev/?https://slack.com/api/users.profile.set', {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${$UserInfo.slackToken?.authed_user?.access_token}`,
						'Content-type': 'application/json; charset=utf-8'
					},
					body: JSON.stringify({
						profile: slackStatus
					})
				}).then(async (r) => console.log({ slack: await r.json() }));
			}
			if (inDeepWork && !latestSent) {
				latestSent = true;
				window.api?.call('doNotDisturb', true);
			} else if (!inDeepWork && latestSent) {
				latestSent = false;
				window.api?.call('doNotDisturb', false);
			}
		}, 10000);
	});
	// $: console.log(
	// 	{ categoriesInSession },
	// 	activitywatchToday?.productivityLastHour?.contexts
	// );

	//curl -F
</script>

{#if false && $IsDev}
	<a
		href="https://slack.com/oauth/v2/authorize?user_scope=users.profile:write&client_id=574878277200.772564823666&redirect_uri=https://app.magicflow.com/connected"
		>Add to Slack</a
	>
{/if}

<div class="w-full">
	<!-- <div
		class="grid bg-black bg-opacity-20 rounded-full text-xs items-center justify-center text-center uppercase font-semibold "
		style="grid-template-columns: 1fr 1fr 1fr !important;"
	>
		{#each timePeriods as period, i}
			<div
				class="p-2 {timePeriod === i ? 'bg-black rounded-2xl' : ''}"
				on:click={() => (timePeriod = i)}
			>
				{period.timeDescriptor}
			</div>
		{/each}
	</div> -->

	<div class="flex flex-col items-center gap-0 w-full">
		<div class="flex justify-between w-full">
			<button
				id="startFocusSession"
				disabled={!increase}
				class="w-full
				 mt-6 relative rounded-2xl p-0 flex items-center justify-between gap-4 text-sm"
				style="background:linear-gradient(135deg, #0f1f4f, rgba(49, 111, 226, 0.133));color: white;"
				on:click={async () => {
					showActivity = { ...increase, ...(activitywatchToday?.productivityLastHour || {}) };
					console.log({ showActivity });
					$DateRange.daysInPast = $DateRange.daysInPast;
					if (!power) return;
				}}
			>
				<div
					class="text-xs absolute centreInContext -top-1.5 px-1.5 pt-0.5"
					style="background:#0f1f4f;border-radius: 0.5rem 0.5rem 0 0;"
				>
					Current Session
				</div>
				<div class="flex items-center gap-4 w-full">
					<div
						class="{$UserInfo.syncActivityWatchEnabled
							? ''
							: 'hidden'} text-white relative text-opacity-80 flex flex-col w-full mr-auto justify-between gap-1"
					>
						<div class="flex p-2 px-3 items-start justify-between gap-2 relative z-10">
							<Icon attribute={'work'} color="white" />
							<div class="w-max">
								{makeDecimalIntoTime(
									activitywatchToday?.productivityLastHour?.duration / 3600 || 0,
									'durationNoZero'
								)}
							</div>
							<div class="flex flex-grow reinvert my-auto ml-2 items-center gap-1 text-xs h-full">
								{#each aggregateAndSort(activitywatchToday?.productivityLastHour?.events || activitywatchToday?.window?.events?.filter((e) => e.timestamp >= activitywatchToday?.productivityLastHour?.timestamp)).slice(0, 4) as [type, data] (data.keyToAggregateOn)}
									<Favicon {type} {data} />

									<div class="relative reinvert -left-1">
										{makeDecimalIntoTime(data?.duration / 3600, 'durationNoZeroWithSeconds')}
									</div>
								{/each}
							</div>
							<!-- <div class=" flex items-right ml-auto font-medium text-xs gap-0.5">
								<div>
									{dayjs(activitywatchToday?.productivityLastHour?.timestamp).format('HH:mm')}
								</div>
								<div>-</div>
								<div>
									{dayjs().diff(
										dayjs(activitywatchToday?.productivityLastHour?.endDate),
										'minute'
									) < 5
										? 'Now'
										: dayjs(activitywatchToday?.productivityLastHour?.endDate).format('HH:mm')}
								</div>
							</div> -->
						</div>
						<!-- <TimeTracked
							since={dayjs(
								activitywatchToday?.productivityLastHour?.start || dayjs().subtract(10, 'minute')
							)}
							numberOfMinutes={Math.ceil(
								10 + (activitywatchToday?.productivityLastHour?.duration || 0) / 60
							) || 25}
							small={'true'}
							showTimeSmall={true}
							showImpacts={false}
							divName={'PreviewActivity'}
							show={['time']}
						/> -->
					</div>
				</div>
				<!-- {#if $CurrentActivity.duration && $CurrentActivity.operator === increase?.operator}
					<div class="flex items-center justify-center gap-1">
						<Icon color={'white'} attribute={$CurrentActivity.attributes?.[0]} />
						<div class="ml-2">
							{Math.floor($CurrentActivity.duration / 3600)
								? ('0' + Math.floor($CurrentActivity.duration / 3600)).slice(-2) + ':'
								: ''}{('0' + (Math.floor($CurrentActivity.duration / 60) % 60)).slice(-2)}:{(
								'0' +
								($CurrentActivity.duration % 60)
							).slice(-2)}
						</div>
					</div>
				{/if} -->
				{#if showActivity || increase}
					<Activity
						{latestIsWork}
						increase={showActivity || increase}
						bind:summary
						{todaysDate}
						bind:showActivity
					/>
				{/if}
			</button>
		</div>
	</div>
</div>

<!-- 
	<div
		on:click={() => {
			increase = $CurrentActivity;
			showActivity = true;
		}}
		class="absolute top-0 w-full left-0 p-1 flex justify-between items-center font-semibold cursor-pointer rounded-xl"
		style="background:{currentColor}"
	>
		
	</div>
{/if} -->
<style>
	button:before {
		content: ' ';
		display: block;
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0.6;
		background-image: url(/bg/bg-multi-dark-15kb.webp);
		background-size: cover;
		background-position: center;
		filter: blur(20px);
	}
</style>
