<script>
	import d2i from 'dom-to-image';
	const { toPng } = d2i;
	import dayjs from 'dayjs';
	import { onMount, onDestroy } from 'svelte';
	import TimeTracked from './TimeTracked.svelte';
	import Details from './Details.svelte';
	// import DayCircle from './DayCircle.svelte';
	import Modal from '../Modal.svelte';
	import { getUserInfo, postUserInfo, sleep, checkLocal } from '$lib/utils.js';

	import { updateScreenTime } from '$lib/connectUtils.js';
	import {
		DateRange,
		Tracking,
		IsNative,
		IsElectron,
		UserEmail,
		UserInfo,
		IsDev,
		LiveConnections,
		Data,
		TodaysData,
		Promises,
		Path,
		ShowDetailsFor,
		Platform
	} from '$lib/store.js';

	let todaysDayjs = dayjs().subtract(4, 'hour');
	export let datesToAnalyse;
	$: todaysDayjs = dayjs().subtract(4, 'hour').subtract($DateRange.daysInPast, 'day');

	let todaysDate = todaysDayjs.format('YYYY-MM-DD');
	$: if (todaysDayjs.format('YYYY-MM-DD') !== todaysDate)
		todaysDate = todaysDayjs.format('YYYY-MM-DD');

	let previousPowerSent;
	let previousLatestIsWork;
	let previousProductivityScore;
	let latestIsWork = true;
	let mounted;

	onMount(() => {
		// let localScores = checkLocal('powerScores');
		// if (localScores) {
		// 	if (
		// 		localScores.readiness &&
		// 		dayjs(localScores.projected_potential[180].timestamp).format('YYYY-MM-DD') === todaysDate
		// 	) {
		// 		setPower(localScores);
		// 	}
		// }
		window.d2itp = toPng;
		if ($IsElectron) {
			window.api?.remove && window.api?.remove('latestIsWork');
			window.api?.receive('latestIsWork', (latest) => {
				console.log({ latestIsWork, latest });
				if (latest !== undefined) latestIsWork = latest;
				$Tracking.latestIsWork = latest;
				captureAndSendImage(undefined, 'onmount');
			});
			window.api?.receive('currentSession', (currentSession) => {
				console.log({ currentSession });
				// $Tracking.currentSession = currentSession;
				updateScreenTime(undefined, currentSession);
			});
		} //else $ShowDetailsFor[$Path] = 'power';

		setTimeout(() => {
			mounted = true;
			if (window.imageinterval) window.imageinterval = clearInterval(window.imageinterval);
			window.imageinterval = setInterval(async () => {
				if ($IsElectron) {
					captureAndSendImage(undefined, 'inverval' + dayjs().format());
				}
			}, 15000);
		}, 250);
	});

	// $: console.log({
	// 	dataIndex,
	// 	d: dayjs().format().slice(0, 16),
	// 	p: power?.find((powerScore) => {
	// 		return dayjs().format().slice(0, 16) === powerScore.timestamp.slice(0, 16);
	// 	})
	// });

	onDestroy(() =>
		typeof window !== 'undefined'
			? (window.imageinterval = clearInterval(window.imageinterval))
			: ''
	);
	let detailsOptions = ['work', 'power'];

	let loginChecked;
	getUserInfo('recentsummary');
	$: if (
		!loginChecked && // only check this once
		$UserInfo.latestLogin && // if we don't have a latest login, don't show power
		dayjs($UserInfo.latestLogin).subtract(4, 'hour').format('YYYY-MM-DD') !== todaysDate
	) {
		$UserInfo.latestLogin = dayjs().format();
		loginChecked = true;
		// $Tracking.mood.tracking = false;
	} else if (!loginChecked) {
		loginChecked = true;
		$UserInfo.latestLogin = dayjs().format();
	}
	$: if (loginChecked && typeof window !== 'undefined') postUserInfo();
	let lastUpdated;
	async function captureAndSendImage(delay, source) {
		if ($IsElectron) {
			await sleep(delay || 0);
			let activitywatchToday =
				$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
					dayjs().subtract(4, 'hour').format('YYYY-MM-DD')
				];
			let productivityScore = Math.round(
				activitywatchToday?.productivityLastHour?.productivityScore * 100
			);
			if (previousLatestIsWork === latestIsWork && productivityScore === previousProductivityScore)
				return;
			let wrapper = document.createElement('div');
			wrapper.style.cssText = 'position:fixed;top:-50%;left:10%;z-index:-1;';
			let node = document.createElement('div');
			node.innerHTML = `
			<div style="width:max-content;text-align:right;margin-left:4px;padding-bottom:1.4px;">
				${
					Math.floor(Math.max(activitywatchToday?.productivityLastHour?.duration || 0, 0) / 60) || 0
				}<span style="font-size:0.8em;">m</span>
			</div>
			<div class="rounded-full" style="margin-left:16px;margin-right:16px;height:48px;width:48px;background: ${
				latestIsWork ? '#00ff33' : '#ff5050'
			}"></div>
			<div class="flex items-center" style="color:${
				activitywatchToday?.productivityLastHour?.productivityScore > 0.666
					? '#00ff33'
					: `${
							activitywatchToday?.productivityLastHour?.productivityScore > 0.333
								? 'yellow'
								: '#ff5050'
					  }`
			}">
				<div style="padding-bottom:1.4px;width:max-content;">
				${!isNaN(productivityScore) ? productivityScore : '--'}
				</div>
				<svg xmlns="http://www.w3.org/2000/svg" style="margin:4px 0 0 4px;height:48px;width:48px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
					<path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
				</svg>
			</div>`;

			node.style.cssText =
				'width: fit-content;padding: 0px 5px 0 16px;font-size: 40px; font-weight: 600; height: 64px;  border: 3px solid black; background: black;color: white; border-radius: 500px; display:flex; justify-content: center; align-items: center;';
			document.body.appendChild(wrapper);
			wrapper.appendChild(node);
			if (previousLatestIsWork === latestIsWork && productivityScore === previousProductivityScore)
				return;

			let png = node ? await toPng(node) : '';
			document.body.removeChild(wrapper);
			if (png) {
				window.api?.send('updateImage', png);
				lastUpdated = Date.now();
			}
			previousLatestIsWork = latestIsWork;
			previousProductivityScore = productivityScore;
		}
	}

	let graphName = 'recentSummary';
	let dataExists;

	// if ($IsNative && $UserEmail && !$Tracking.mood.latest) $Tracking.mood.tracking = true;
	// $: if (
	// 	!$Tracking.mood.tracking &&
	// 	((!(
	// 		$Tracking.mood.latest ||
	// 		(Object.values($Data['["timeseries","time_series/ethi","noCache"]'] || {}).flat() &&
	// 			Object.values($Data['["timeseries","time_series/ethi","noCache"]'] || {}).flat().length)
	// 	) &&
	// 		$Tracking.mood.tracking !== false) ||
	// 		(!dataExists && $Tracking.mood.tracking !== false))
	// )
	// 	$Tracking.mood.tracking = true;

	let total_time;

	let eventsIn10mins;
	let showDailyReview = false;
	export let selectedTimePeriod;
	let monthView = 'sankey';
	let weekView = 'sankey';
</script>

<!-- <div
	class="grid gap-2 w-full absolute items-center justify-items-center -top-2 left-0 z-10"
	style="grid-template-columns: 1fr 1fr !important;"
>
	{#each detailsOptions as detail, i}
		<button
			on:click={() => ($ShowDetailsFor[$Path] = (detail !== 'work' && detail) || '')}
			class="p-0 pt-1  rounded-none font-semibold uppercase bg-transparent  {$ShowDetailsFor[
				$Path
			] === detail ||
			(!$ShowDetailsFor[$Path] && !i)
				? 'border-b-2 border-white text-white border-solid'
				: 'text-gray-400'}"
			style="font-size: 0.66rem;"
		>
			{detail}
		</button>
	{/each}
</div> -->
{#if $ShowDetailsFor[$Path] && !detailsOptions.includes($ShowDetailsFor[$Path])}
	<Modal
		bind:show={$ShowDetailsFor[$Path]}
		options={{
			cssText: `display:flex;flex-direction:column;align-items:stretch;justify-content:space-around;`,
			fullScreen: true,
			topLevel: true
		}}
	>
		<div class="uppercase font-semibold text-center">
			Your {$ShowDetailsFor[$Path]}
		</div>
		<Details bind:showDetailsFor={$ShowDetailsFor[$Path]} />
	</Modal>
{/if}
{#if false && $IsDev}
	<Modal
		show={true}
		options={{
			cssText: `display:flex;flex-direction:column;align-items:stretch;justify-content:space-around;`,
			fullScreen: true,
			topLevel: true
		}}
	>
		<TimeTracked show={['sankey', 'comparisons', 'time']} divName={'simpleModal'} />
	</Modal>
{/if}
{#if $ShowDetailsFor[$Path] && detailsOptions.includes($ShowDetailsFor[$Path])}
	<div
		class="relative w-full h-full flex flex-col items-stretch justify-around p-2 pt-4"
		style={$ShowDetailsFor[$Path] && detailsOptions.includes($ShowDetailsFor[$Path])
			? ''
			: 'opacity:0;position:absolute;bottom:100%;pointer-events:none;max-width:100vw;z-index:0;'}
	>
		<Details bind:showDetailsFor={$ShowDetailsFor[$Path]}>
			<!-- <div class="uppercase text-sm text-center font-semibold mt-4 mb-2 text-gray-300">TODAY</div> -->
		</Details>
	</div>
{/if}

<div
	class="w-full h-full flex flex-col items-stretch justify-between max-w-full pt-0"
	style={!$ShowDetailsFor[$Path]
		? ''
		: 'opacity:0;position:absolute;bottom:100%;pointer-events:none;max-width:98vw;z-index:0;'}
>
	<!-- <DayCircle {eventsIn10mins} {wakeup} {appleTimeSeries} {todaysDate} /> -->
	<!-- <div
		on:click={() => ($ShowDetailsFor[$Path] = 'screentime')}
		class="cursor-pointer text-indigo-100 text-xs text-center rounded-full border border-opacity-10 border-gray-100 pl-4 pr-3.5 py-1.5 w-30 bg-gray-900 bg-opacity-70"
	>
		<div class="font-bold text-lg">
			{#if activitywatch && activitywatch[todaysDate] && productivityMetrics?.productivityScore}
				{Math.round(
					(((activitywatch[todaysDate]?.window || activitywatch[todaysDate])?.duration || 0) *
						productivityMetrics?.productivityScore) /
						36
				) / 10}
			{:else}
				--
			{/if}
		</div>
		TRACTION
	</div> -->
	<!-- <div class="text-blue-700">
  <div class="font-bold text-xl">55%</div>
  OVERLOAD
</div> -->
	<!-- {#if !$DateRange.daysInPast && !showDailyReview}
		<IncreasePower
			bind:power
			{dataIndex}
			{powerPeaks}
			{powerDips}
			{todaysData}
			{wakeup}
			{total_time}
			{todaysDate}
			{latestIsWork}
			sleep_debt={scores.sleep_debt}
		/>
	{/if} -->
	<div
		class="w-full flex flex-col flex-grow justify-between items-stretch  {!showDailyReview
			? 'pb-6'
			: 'h-screen-safe p-2 bg-gray-900 py-12 w-screen fixed bg-opacity-100 top-0 left-0 z-40'} "
		style={!showDailyReview ? 'border-radius: 1.5rem;' : 'margin-top: env(safe-area-inset-top);'}
	>
		<div
			class="{!showDailyReview
				? ''
				: ''} transition-opacity text-center flex flex-col items-stretch justify-around flex-grow gap-2"
		>
			<!-- {#if !$DateRange.daysInPast && !showDailyReview}
				<div class="font-semibold">Day so far</div>
			{/if} -->
			{#if selectedTimePeriod === 'month' && !$ShowDetailsFor.time}
				<div class="flex justify-center gap-2 relative z-20">
					{#each ['sankey', 'timeLines'] as view}
						<button
							class="px-3 capitalize pt-0 pb-0.5 {monthView === view
								? 'bg-gray-800 text-white'
								: 'bg-transparent text-gray-300'}"
							on:click={() => (monthView = view)}>{view}</button
						>
					{/each}
				</div>
			{/if}
			{#if selectedTimePeriod === 'week' && !$ShowDetailsFor.time}
				<div class="flex justify-center gap-2 relative z-20">
					{#each ['sankey', 'comparisons'] as view}
						<button
							class="px-3 capitalize pt-0 pb-0.5 {weekView === view
								? 'bg-gray-800 text-white'
								: 'bg-transparent text-gray-300'}"
							on:click={() => (weekView = view)}>{view}</button
						>
					{/each}
				</div>
			{/if}

			<TimeTracked
				bind:eventsIn10mins
				{todaysDate}
				{todaysDayjs}
				{datesToAnalyse}
				show={selectedTimePeriod === 'day' || !selectedTimePeriod
					? ['sankey', 'comparisons', 'time']
					: (selectedTimePeriod === 'week' ? [weekView, 'timeLines'] : [monthView]) ||
					  (showDailyReview
							? ['flow', 'activities', 'sessionOrbs']
							: ['comparisons', 'time', 'metricGraphs', 'timeLine', 'categoryBreakdown'])}
				bind:total_time
				showImpacts={showDailyReview}
			/>
		</div>
		<!-- <button
			on:click={() => {
				if ($DateRange.daysInPast) shiftPeriods(-$DateRange.daysInPast);
				showDailyReview = !showDailyReview;
			}}
			class="w-full border border-solid border-gray-800 mt-1"
			>{showDailyReview || $DateRange.daysInPast ? 'Finish reviewing' : 'Review your day'}</button
		> -->
	</div>
</div>
