<script>
	import LoadingOrb from './LoadingOrb.svelte';
	import ListErrors from './ListErrors.svelte';
	import { DataUploaded, GraphsCached, DemoMode } from '$lib/store.js';
	import { getGraph } from '$lib/utils.js';
	import { dataRequirements } from '$lib/graphLists.js';
	import { goto } from '$app/navigation';

	export let graphName = undefined;
	export let promise = getGraph(graphName);
	export let expectedHeight = 300;
	let loaded;
	export let showError = true;
	export let isGraph = false;
	export let formatFunction = undefined;
	export let loadingText = undefined;
	promise.then((data) => {
		loaded = true;
	});
	let awaitPromise = promise.catch((e) => {
		loaded = true;
		console.error(e);
		return;
	});
	let ignoreEmptyCache;
	function showAnyway() {
		ignoreEmptyCache = true;
	}
</script>

{#if !ignoreEmptyCache && isGraph && ($GraphsCached || []).length && !$GraphsCached.includes(graphName) && dataRequirements[graphName] && dataRequirements[graphName].every( (requirement) => Object.values($DataUploaded || {})
				.reduce((a, b) => a.concat(b), [])
				.includes(requirement) )}
	<card class="centreInContext">
		Try refreshing the page - it might be that this page is still processing and getting ready. If
		it persists, come back in 5 minutes!
		{#if window.location.href.includes('localhost') || window.location.href.includes('hedge')}
			<button on:click={showAnyway}>Show Anyway</button>
		{/if}
	</card>
{:else}
	{#await awaitPromise}
		<!-- This is to fix a bug that shouldn't be happening where LoadingOrb doesn't get destroyed... -->
		<LoadingOrb {loaded} {loadingText} {expectedHeight} {graphName} fullScreen={false} />
	{:then res}
		{#if !$DemoMode && graphName != 'Indieness' && (!res || (typeof res == 'string' && res.toUpperCase() === 'NO CONTENT') || (typeof res == 'object' && !Object.values(res).some((i) => i)))}
			{#if showError}
				<card class="centreInContext">
					<div class="text-xl text-center flex justify-center gap-16">
						👀
						<span style="transform: scaleX(-1);"> 👀 </span>
					</div>
					<ListErrors errors={'No data found'} brokenThing={graphName} />
					<div class="flex gap-2 justify-center">
						<a on:click|preventDefault={() => goto('data')} href="/data">
							<button class="py-0.5 px-1 text-sm">Add Data</button>
						</a>
					</div>
				</card>
			{/if}
		{:else}
			<slot res={formatFunction ? formatFunction(res) : res} />
		{/if}
	{:catch error}
		{#if showError}
			<card class="centreInContext">
				<div class="text-xl text-center flex justify-center gap-16">
					👀
					<span style="transform: scaleX(-1);"> 👀 </span>
				</div>
				<ListErrors errors={'No data found'} brokenThing={graphName} />
				<div class="flex gap-2 justify-center">
					<a on:click|preventDefault={() => goto('data')} href="/data">
						<button class="py-0.5 px-1 text-sm">Add Data</button>
					</a>
				</div>
			</card>
		{:else}
			<slot res={false} />
		{/if}
	{/await}
{/if}

<style lang="postcss">
	/* p {
		text-align: center;
		align-self: center;
		width: 100%;
		padding: 20px;
	} */
	card {
		@apply max-w-sm w-full absolute bg-gray-900 bg-opacity-90;
	}
</style>
