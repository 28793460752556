<script>
	import TimeTracked from './TimeTracked.svelte';

	import SparkLine from './chartFunctions/SparkLine.svelte';
	import AwaitBlock from '../AwaitBlock.svelte';
	import GlowingMetric from '../GlowingMetric.svelte';
	// import Health from './Health.svelte';
	// import InsightCard from '../InsightCard.svelte';
	// import Modal from '../Modal.svelte';
	import Icon from '../Icon.svelte';
	import { onMount } from 'svelte';
	import { getUserInfo, postUserInfo, sleep, checkLocal } from '$lib/utils.js';
	import { setPowerNotifications } from '$lib/notifications.js';
	import {
		getTodaysData,
		getWakeUp,
		getWhoopSleep,
		findPeaks,
		generateSleepFromSteps
	} from '$lib/dataProcessing.js';
	import {
		makeDecimalIntoTime,
		getAppleHealthData,
		formatSleepDump,
		calculateSleepDebt,
		calculateSleepNeed,
		queryWhoop,
		calculateScores
	} from '$lib/connectUtils.js';
	import {
		DateRange,
		IsElectron,
		LiveConnections,
		Data,
		TodaysData,
		Path,
		ShowDetailsFor
	} from '$lib/store.js';
	import {
		Tracking,
		UserInfo,
		Promises,
		IsNative,
		IsDev,
		Platform,
		UserEmail
	} from '$lib/store.js';
	export let showDetailsFor;
	export let powerImpacts;
	export let powerImpactAggregated;

	export let wakeup;
	export let scores;
	export let debt;
	export let todaysDate;
	export let metric;
	export let loaded;
	export let powerPeaks;
	export let powerDips;
	export let todaysData;
	export let dataIndex;
	export let power;

	let mounted;
	onMount(() => (mounted = true));

	let promisesLoaded;
	let appleTimeSeries;
	let whoop;
	let whoopReturned;
	let sleepData;
	let recentSleep;
	let sleepDataUsed;
	let formatSleepDumpPromise;
	let wakeupFromToday;
	let powerNotificationsBeingSet;
	let arePowerNotificationsBeingSet = (beingSet) => (powerNotificationsBeingSet = beingSet);
	let getPowerNotificationsBeingSet = () => powerNotificationsBeingSet;
	let powerNotificationsShouldBeSet = () => $UserInfo.powerNotifications && $IsNative;
	$: if (powerNotificationsShouldBeSet() && wakeup && powerPeaks) {
		if (!getPowerNotificationsBeingSet()) {
			arePowerNotificationsBeingSet(true);
			setPowerNotifications(powerPeaks, powerDips, wakeup);
			arePowerNotificationsBeingSet(false);
		}
	}

	$: dataIndex = power?.findIndex((powerScore) => {
		return dayjs().format().slice(0, 16) === powerScore.timestamp.slice(0, 16);
	});
	$: metric =
		power && power[dataIndex] && power[dataIndex].value && Math.round(power[dataIndex].value);

	setTimeout(async () => {
		await Promise.allSettled(Object.values($Promises));
		promisesLoaded = true;
		await sleep(5000);
		if (formatSleepDumpPromise) await formatSleepDumpPromise;
		await sleep(5000);
		if (false && appleTimeSeries && !debt && !recentSleep?.length) {
			let stepSleep = generateSleepFromSteps(appleTimeSeries);
			if (stepSleep && !debt && !recentSleep?.length) {
				let latestStepWakeup = stepSleep.map((s) => dayjs(s.endDate)).sort((a, b) => b - a)[0];
				if (!latestStepWakeup) return;
				wakeup = dayjs()
					.subtract(4, 'hour')
					.hour(latestStepWakeup?.hour())
					.minute(latestStepWakeup?.minute());
				debt = calculateSleepDebt(stepSleep, calculateSleepNeed(stepSleep));
			}
		}
		setTimeout(() => {
			loaded = true;
		}, 3000);
	}, 6000);

	let todaysDayjs = dayjs().subtract(4, 'hour');
	$: todaysDayjs = dayjs().subtract(4, 'hour').subtract($DateRange.daysInPast, 'day');

	todaysDate = todaysDayjs.format('YYYY-MM-DD');
	$: if (todaysDayjs.format('YYYY-MM-DD') !== todaysDate)
		todaysDate = todaysDayjs.format('YYYY-MM-DD');
	let yesterdaysDate = todaysDayjs?.subtract(1, 'day').format('YYYY-MM-DD');
	$: yesterdaysDate = todaysDayjs?.subtract(1, 'day').format('YYYY-MM-DD');

	queryWhoop($DateRange.daysInPast).then((a) => (whoopReturned = true));
	$: if (
		!appleTimeSeries ||
		Object.values($Data[`["timeseries","time_series/apple","noCache"]`] || {}).length !==
			Object.values(appleTimeSeries).length ||
		$Data[`["timeseries","time_series/apple","noCache"]`]?.lastUpdated !==
			appleTimeSeries?.lastUpdated
	)
		appleTimeSeries =
			($Data && $Data['["timeseries","time_series/apple","noCache"]']) ||
			appleTimeSeries ||
			checkLocal('["timeseries","time_series/apple","noCache"]');

	$: if (
		!whoop ||
		Object.values($Data[`["timeseries","time_series/whoop","noCache"]`] || {}).length !==
			Object.values(whoop).length
	)
		whoop = $Data[`["timeseries","time_series/whoop","noCache"]`];
	$: whoopSleep = getWhoopSleep(whoop);

	$: todays = appleTimeSeries && appleTimeSeries[todaysDate];

	$: if (todays || whoop) {
		todaysData = getTodaysData(todays, appleTimeSeries, whoop, todaysDate, $UserInfo);
	}
	// else {
	// }

	let sleepReturned;
	let setSleepReturned = () => (sleepReturned = true);
	let sleepStore;
	$: if (
		(!sleepStore && $Data[`["sleep.json","single/apple","noCache"]`]?.length) ||
		(sleepStore && sleepStore?.length !== $Data[`["sleep.json","single/apple","noCache"]`]?.length)
	)
		sleepStore = $Data[`["sleep.json","single/apple","noCache"]`];
	$: latestSleep = sleepStore && sleepStore[sleepStore?.length - 1];
	$: if ($UserEmail && $DateRange)
		getAppleHealthData('sleepAnalysis', 'sleep.json')
			.then(setSleepReturned)
			.catch(setSleepReturned);
	$: if (
		(!sleepData || sleepData?.length < sleepStore?.length) &&
		((latestSleep && dayjs(latestSleep.endDate).format('YYYY-MM-DD') === todaysDate) ||
			sleepReturned) &&
		(!$UserInfo.syncWhoopEnabled ||
			$LiveConnections?.whoop?.needsWhoopCredentials ||
			whoopSleep?.length >= 14 ||
			whoopReturned)
	)
		sleepData = [
			...((typeof sleepStore === 'object' && sleepStore.length && sleepStore) || []),
			...(whoopSleep || []),
			...Object.values(appleTimeSeries || {})
				.map((day) => day?.sleepAnalysis?.resultData)
				.flat()
		].sort((a, b) => dayjs(a.startDate) - dayjs(b.startDate));

	$: if (
		sleepData &&
		!formatSleepDumpPromise &&
		(!recentSleep ||
			sleepDataUsed?.length !==
				sleepData?.filter((sleep) => sleep && dayjs(sleep.endDate) < dayjs(todaysDate).endOf('day'))
					.length)
	) {
		sleepDataUsed = sleepData?.filter(
			(sleep) => sleep && dayjs(sleep.endDate) < dayjs(todaysDate).endOf('day')
		);
		formatSleepDumpPromise = formatSleepDump(sleepDataUsed, false, 'asleep', true).then(
			(sleeps) => {
				recentSleep = sleeps;
				formatSleepDumpPromise = undefined;
			}
		);
	}

	$: if ((!wakeup || !wakeupFromToday) && (todaysData?.sleep?.data || recentSleep)) {
		wakeupFromToday = todaysData?.sleep?.data;
		wakeup = getWakeUp(todaysData?.sleep?.data || recentSleep);
	}
	$: debt = recentSleep?.length && calculateSleepDebt(recentSleep, calculateSleepNeed(recentSleep));
	// $: if (debt || recentSleep) console.log({ debt, recentSleep });

	let yesterdaysSteps;
	$: if (
		!yesterdaysSteps &&
		typeof $Data[`["steps.json","single/apple","noCache"]`] === 'object' &&
		$Data[`["steps.json","single/apple","noCache"]`].length
	) {
		yesterdaysSteps = $Data[`["steps.json","single/apple","noCache"]`]
			.slice($Data[`["steps.json","single/apple","noCache"]`].length - 5)
			.find(
				(s) =>
					dayjs(s.startDate).format('YYYY-MM-DD') ===
					todaysDayjs?.subtract(1, 'day').format('YYYY-MM-DD')
			);
	}
	let baselineScores;

	$: powerImpacts = {
		recent_steps: -4 + (yesterdaysSteps?.value || 0) / 1250,
		recent_heart_rate:
			todaysData?.heart_rate?.average &&
			todaysData.heart_rate.value &&
			todaysData.heart_rate.average - todaysData.heart_rate.value, // -1 for each beat above average
		recent_sleep: 6 - (scores?.sleep_debt || 0) * 3, // -3 for each hour of debt
		todays_sleep:
			6 -
			(debt?.sleepWithDebt &&
				debt.sleepWithDebt[debt.sleepWithDebt?.length - 1].debtContributions) *
				3, // -3 for each hour of debt incurred today
		todays_recovery: baselineScores?.readiness ? Number(baselineScores?.readiness) - 90 : undefined,
		todays_steps: -4 + (todaysData?.steps?.value || 0) / 1250,
		todays_heart_rate:
			todaysData?.heart_rate?.average &&
			todaysData.heart_rate.value &&
			todaysData.heart_rate.average - todaysData.heart_rate.value // -1 for each beat above average
	};

	let newPowerImpactAggregated;
	let setNewPowerImpact = () =>
		(newPowerImpactAggregated =
			Object.entries(powerImpacts)
				.filter(
					(a) =>
						!a?.[0].includes('recovery') &&
						!a?.[0].includes('todays') &&
						a?.[0] !== 'recent_sleep' &&
						a[1]
				)
				.map((a) => a[1])
				.reduce((a, b) => a + b, 0) || 0);
	$: if (powerImpacts) setNewPowerImpact();
	$: if (powerImpactAggregated !== newPowerImpactAggregated)
		powerImpactAggregated = newPowerImpactAggregated;

	$: baselineScores = (debt && calculateScores(debt.debt, wakeup)) || {};
	$: baselineScores && setPower(baselineScores, powerImpactAggregated);

	let peaksAndDips;

	function setPower(baselineScores) {
		if (
			!(
				baselineScores &&
				baselineScores.sleep_debt !== undefined &&
				powerImpactAggregated !== undefined
			)
		) {
			console.error('missing one of: ', { baselineScores, powerImpactAggregated });
			return;
		}
		scores = { ...(baselineScores || []) };
		peaksAndDips = findPeaks(baselineScores.projected_potential.map((p) => p.value));
		powerPeaks = peaksAndDips?.positions.map((position) => ({
			timestamp: baselineScores.projected_potential[position].timestamp,
			position: position + 30
		}));
		powerDips = peaksAndDips?.minimaPositions.map((position) => ({
			timestamp: baselineScores.projected_potential[position].timestamp,
			position: position + 30
		}));

		let todaysSteps = todaysData && todaysData.incrementalSteps;
		let maxSteps = todaysSteps?.map((s) => s.value).reduce((a, b) => a + b, 0);
		let todaysStepsImpact = -4 + (maxSteps || 0) / 1250;
		let aggregateImpacts = todaysStepsImpact + (powerImpactAggregated || 0);
		let peak = baselineScores.readiness + aggregateImpacts;

		let offset = peak >= 99 ? peak - 98 : 0;
		power = [
			...Array(baselineScores.minuteDiff || 30)
				.fill()
				.map((pp, i) => {
					pp = {
						timestamp: dayjs(scores.projected_potential?.[0].timestamp)
							.subtract(baselineScores.minuteDiff - i, 'minutes')
							.format(),
						value:
							scores.projected_potential?.[0].value -
							0.17 * (baselineScores.minuteDiff - i) * Math.exp(i / 500)
					};
					return pp; //73.72 + 70.3 - 0.145 - 73.72
				}),
			...scores.projected_potential
		].map((pow) => {
			let stepsSoFar = todaysSteps
				?.filter((step) => step && dayjs(step.endDate) <= dayjs(pow.timestamp))
				.map((s) => s.value)
				.reduce((a, b) => a + b, 0);
			let todaysStepsImpact = -4 + (stepsSoFar || 0) / 1250;
			let aggregateImpacts = todaysStepsImpact + (powerImpactAggregated || 0);
			let newPower = { ...(pow || []) };
			newPower.value += aggregateImpacts - offset;
			return newPower;
		});
		scores.readiness = baselineScores.readiness + powerImpactAggregated;
		// storeLocal('powerScores', scores);

		return power;
	}
	$: if (!$IsDev && showDetailsFor && typeof window !== 'undefined')
		window.analytics?.track?.('Details Viewed', {
			platform: $Platform,
			metric: showDetailsFor,
			environment: $IsDev ? 'dev' : 'production',
			email: $UserEmail
		});

	let details = {
		power: {
			subtitles: [
				`<span style="color:#64b0ff">Power</span> = <span style="color:#00D5ff">energy</span> + <span style="color:#1485ff">focus</span>`,
				`i.e. your ability to get shit done`
			],
			info: [
				`We predict it from circadian rhythm, activity, and biometrics (heart rate, HRV, etc.).`,
				`You should schedule your most challenging tasks in your Peak zones.`
			],
			text: [],
			graph: true
		},
		'sleep debt': {
			text: [
				'Your sleep debt is a core contributor to both your recovery and power scores.',
				"Getting enough sleep isn't something you can skip! As you record your mental state, we'll start finding insights into exactly how your sleep affects your mental state."
			]
		},
		recovery: {
			text: [
				"Your recovery score tells you how prepared your mind is for today's challenges and opportunities.",
				"It's a composite score of your heart rate, HRV, sleep last night and sleep debt. This score is about how ready your mind is to perform given your habits over the last weeks.",
				'If you record your mental state, your recovery score learns more about you and will become more accurate.'
			]
		},
		steps: {
			text: ["Shows how much you've been walking for the day. More analysis to come here soon."]
		},
		heart_rate: {
			text: ['How your resting, average, and current heart rate are affecting your power levels.']
		},
		screentime: {
			text: [
				''
				// `See how much time you've spent on the computer, and how productive you've been.`
			]
		}
	};
	let graphName = 'details';

	// else {
	//   setTimeout(() => {
	//     if ($UserInfo.powerNotifications === undefined && !showDetailsFor)
	//       showDetailsFor = "power"
	//   }, 10000)
	// }
	let powerNotificationButtonText = 'Get Power notifications';
	let promise = new Promise(async (resolve) => {
		await sleep(8000);
		await Promise.all(Object.values($Promises));
		resolve();
	});
	const handle_keydown = (e) => {
		if (!showDetailsFor) return;
		// console.log({ e });
		if (e.key === 'Escape') {
			showDetailsFor = '';
			return;
		}
	};
</script>

<!-- <Modal
	bind:show={showDetailsFor}
	options={{
		topLevel: true,
		fullScreen: true,
		cssText:
			showDetailsFor === 'power'
				? 'justify-content: space-between;'
				: 'justify-content: space-around;'
	}}
> -->

<svelte:window on:keydown={handle_keydown} />

<!-- {#if showDetailsFor === 'steps' || (showDetailsFor && showDetailsFor.includes('sleep')) || (showDetailsFor && showDetailsFor.includes('heart'))}
	<Health
		divName={'Details'}
		{debt}
		show={[
			showDetailsFor === 'steps' ? 'steps' : '',
			showDetailsFor && showDetailsFor.includes('sleep') ? 'sleep' : '',
			showDetailsFor && showDetailsFor.includes('heart') ? 'heart' : ''
		].filter((a) => a)}
	/>
{/if} -->
{#if showDetailsFor === 'screentime'}
	<TimeTracked
		{wakeup}
		divName={'Details'}
		show={showDetailsFor === 'screentime' ? ['categories'] : []}
	/>
{/if}
{#if showDetailsFor === 'recovery'}
	<div class="text-center flex flex-col items-center">
		<div class="text-center text-4xl font-semibold relative">
			{scores.readiness ? Math.min(Math.round(scores.readiness), 100) : '--'}
			<span class="absolute left-full bottom-0 text-lg text-gray-300">%</span>
		</div>
		<div class="flex gap-0.5 items-center">
			<Icon attribute="recovery" includeText={true} />
		</div>
	</div>
	<div>
		<div class="uppercase text-sm text-center font-semibold mt-4 mb-2 text-gray-300">
			Last night's sleep
		</div>
		<div class="flex justify-around mx-4">
			<div
				class="flex relative flex-col items-center bg-gray-800 bg-opacity-40 py-1 px-2 h-full rounded-full"
			>
				<div class="flex items-center gap-0.5">
					<Icon
						attribute={'sleep'}
						includeText={true}
						textOverride={(debt?.sleepWithDebt?.find((s) => s.date === todaysDate)
							? (
									Math.round(
										debt.sleepWithDebt
											.slice(0, 13)
											.map((s) => s.debtContributions)
											.reduce((a, b) => a + b, 0) * 0.769231
									) /
										10 -
									Math.round(
										debt?.sleepWithDebt?.find((s) => s.date === todaysDate) &&
											debt?.sleepWithDebt?.find((s) => s.date === todaysDate).debtContributions * 40
									) /
										10
							  ).toFixed(1) || '-- '
							: '-- ') + '%'}
						size={[2, 2]}
					/>
				</div>
			</div>
		</div>
	</div>
	<div>
		<div class="uppercase text-sm text-center font-semibold mt-4 mb-2 text-gray-300">
			Recent impacts
		</div>
		<div class="flex justify-around mx-4">
			{#each ['steps', 'heart_rate', 'sleep'] as attribute, i}
				<div
					class="flex relative flex-col items-center bg-gray-800 bg-opacity-40 py-1 px-2 h-full rounded-full"
				>
					<div
						class="font-semibold"
						style="color: {!(powerImpacts && powerImpacts['recent_' + attribute])
							? '#999999'
							: `${
									powerImpacts && powerImpacts['recent_' + attribute] > 0 ? '#00ff53' : '#EF226C'
							  }`}"
					>
						{powerImpacts &&
						powerImpacts['recent_' + attribute] !== undefined &&
						!isNaN(powerImpacts['recent_' + attribute])
							? powerImpacts['recent_' + attribute].toFixed(1)
							: '--'}%
					</div>
					<div class="flex items-center gap-0.5">
						<Icon {attribute} includeText={true} />
					</div>
				</div>
			{/each}
		</div>
	</div>
{/if}
{#if showDetailsFor === 'power'}
	{#if !$IsNative && !$UserInfo.syncHKEnabled}
		<div
			class="text-center w-full flex flex-col justify-between gap-4 items-center absolute top-8 left-0 rounded-2xl p-8 z-10"
			style="height: 95%; background: url(pikastyle.webp); background-size: cover;"
		>
			<card class="bg-gray-900 text-left text-sm">
				<div class="font-bold">Power score</div>
				<p class="text-gray-300">
					Your power score is our prediction of your current mental performance level. The higher
					your power, the easier it will be for you to get into flow.
				</p>
				<p class="text-gray-300">
					To get yours, you'll need our iOS app to sync Apple Health with Magicflow. We predict it
					from your circadian rhythm, activity, and biometrics (heart rate, HRV, etc.).
				</p>
				<p class="text-gray-300 font-bold">
					Scan the QR code below with your phone to download it!
				</p>
			</card>
			<img class="rounded-2xl mx-auto w-3/4" src="testFlight.webp" alt="testFlight" />
		</div>
	{:else}
		<div
			id="mainPowerWrapper"
			class="cursor-pointer flex flex-col items-center relative w-full p-4 my-8"
		>
			<GlowingMetric {loaded} {metric}>
				{#if mounted}
					{#key powerDips}
						<SparkLine
							dataIndex={dataIndex || 570}
							divName={'sparkLine'}
							{powerPeaks}
							{powerDips}
							data={power}
							name={'Power'}
						/>
					{/key}
				{/if}
			</GlowingMetric>

			{#each [['sleep debt', 'recovery'], ['steps', 'heart_rate']] as attributes, set}
				{#each attributes as attribute, i}
					<div
						class="absolute flex flex-col {i ? 'right-1.5 text-right' : 'left-1.5'} {set
							? '-bottom-3'
							: '-top-2 flex-col-reverse'} cursor-pointer items-center"
						on:click|stopPropagation={() => (showDetailsFor = attribute)}
					>
						<div
							class="w-full font-semibold text-xs -mb-1"
							style="color: {!(
								powerImpacts &&
								powerImpacts['todays_' + attribute.split(' ')[0]] !== undefined &&
								!isNaN(powerImpacts['todays_' + attribute.split(' ')[0]])
							)
								? '#999999'
								: `${
										(attribute.includes('sleep')
											? powerImpacts['recent_' + attribute.split(' ')[0]]
											: powerImpacts['todays_' + attribute.split(' ')[0]]) >= 0
											? '#00FF8C'
											: '#EF226C'
								  }`}"
						>
							{powerImpacts &&
							powerImpacts['todays_' + attribute.split(' ')[0]] !== undefined &&
							!isNaN(powerImpacts['todays_' + attribute.split(' ')[0]])
								? (attribute.includes('sleep')
										? powerImpacts['recent_' + attribute.split(' ')[0]]
										: powerImpacts['todays_' + attribute.split(' ')[0]]
								  ).toFixed(1)
								: '--'}<span style="font-size:0.6rem">%</span>
						</div>
						<div class="font-bold w-full text-lg opacity-90">
							{#if attribute === 'sleep debt'}
								{#each scores && !isNaN(scores.sleep_debt) ? makeDecimalIntoTime(scores.sleep_debt < 0 ? 0 : scores.sleep_debt) : ['--', '--'] as time, i}
									{time || 0}<span class="text-base">{i ? 'm' : 'h '}</span>
								{/each}
							{:else if attribute === 'recovery'}
								{scores.readiness ? Math.round(scores.readiness) : '--'}<span
									class="text-xs text-gray-300 ml-0.5">%</span
								>
							{:else}
								{todaysData && !isNaN(todaysData[attribute]?.value)
									? todaysData[attribute].value
									: '--'}
							{/if}
						</div>
						<div class="flex items-center gap-0.5 -mt-1 tracking-normal {!i ? '-ml-1' : ''}">
							<Icon
								{attribute}
								includeText={true}
								textOverride={attribute === 'screentime'
									? 'traction'
									: attribute === 'sleep debt'
									  ? 'sleep debt'
									  : ''}
								size={[1.5, 1.1]}
							/>
						</div>
					</div>
				{/each}
			{/each}
		</div>
		{#if scores || loaded}
			<div
				class="flex items-center mt-4 -mb-2 justify-around uppercase font-semibold text-center text-sm"
			>
				{#if loaded && !metric}
					<div>
						You don't have recent enough sleep/steps data available for us to generate a power
						score.
					</div>
				{:else}
					<div class="power">
						<div class="power text-3xl">{metric || '--'}</div>
						<div>Current</div>
					</div>
					<div class="power">
						<div class="power text-3xl">
							{scores.readiness
								? Math.min(Math.round(scores.readiness + (powerImpactAggregated || 0)), 100)
								: '--'}
						</div>
						<div>Potential</div>
					</div>
				{/if}
			</div>
			{#if mounted && $UserInfo.syncHKEnabled}
				<div class="max-h-32" style="color:#64b0ff">
					{#if mounted && $UserInfo.syncHKEnabled}
						{#key powerDips}
							<SparkLine
								{dataIndex}
								divName={'sparkLineDetails'}
								showAxis={true}
								{powerPeaks}
								{powerDips}
								data={power}
								name={'Power'}
							/>
						{/key}
					{/if}
				</div>
			{/if}
			<!-- <div class="m-4 rounded-xl p-1 border border-gray-800">
			{#each [['Peaks', '#64b0ff', ...powerPeaks
						.filter((peak) => peak.position < 1140)
						.map((peak) => dayjs(peak.timestamp).format('HH:mm'))], ['Dips', '#ef0023', ...powerDips
						.filter((peak) => peak.position < 1140)
						.map((peak) => dayjs(peak.timestamp).format('HH:mm'))]] as [type, color, time1, time2]}
				<div
					style="display:grid;grid-template-columns: 1fr 1fr 1fr !important;"
					class="text-center"
				>
					<div class="uppercase">{type}:</div>
					<div class="font-semibold" style="color: {color}">{time1}</div>
					<div class="font-semibold" style="color: {color}">
						{time2 || '--:--'}
					</div>
				</div>
			{/each}
			</div> -->
			<div>
				<div class="uppercase text-sm text-center font-semibold mt-4 mb-2 text-gray-300">
					Recent impacts
				</div>
				<div class="flex justify-around mx-4">
					{#each ['steps', 'heart_rate', 'sleep'] as attribute, i}
						<div
							class="flex relative flex-col items-center bg-gray-800 bg-opacity-40 py-1 px-2 h-full rounded-full"
						>
							<div
								class="font-semibold"
								style="color: {!(powerImpacts && powerImpacts['recent_' + attribute])
									? '#999999'
									: `${
											powerImpacts && powerImpacts['recent_' + attribute] > 0
												? '#00ff53'
												: '#EF226C'
									  }`}"
							>
								{powerImpacts &&
								powerImpacts['recent_' + attribute] !== undefined &&
								!isNaN(powerImpacts['recent_' + attribute])
									? powerImpacts['recent_' + attribute].toFixed(1)
									: '--'}%
							</div>
							<div class="flex items-center gap-0.5">
								<Icon {attribute} includeText={true} />
							</div>
						</div>
					{/each}
				</div>
			</div>
		{:else}
			<AwaitBlock {promise} {graphName} loadingText={'Crunching your data for today'} />
			<div />
		{/if}
	{/if}
{/if}
{#each details?.[showDetailsFor]?.text || [] as paragraph, i}
	<p class={i ? '' : 'font-semibold'}>{paragraph}</p>
{/each}
{#if showDetailsFor === 'power'}
	{#if !($UserInfo && $UserInfo.powerNotifications) && $IsNative}
		<p class="font-medium text-center">Want a nudge before each Peak?</p>
		<button
			class="power text-xl mx-4 my-4 p-3 rounded-full"
			style="-webkit-background-clip:unset;background-clip:unset;color:#000000cc;"
			on:click={() => {
				$UserInfo.powerNotifications = true;
				powerNotificationButtonText = 'Notifications set!';
			}}>{powerNotificationButtonText}</button
		>
	{:else}
		<button
			class="text-xl mx-4 my-4 p-3 rounded-full text-gray-300"
			on:click={() => {
				$Tracking.mood.tracking = true;
				showDetailsFor = false;
			}}>Check in with your mind</button
		>
	{/if}
{/if}
<!-- </Modal> -->
