<script>
	import { IsNative } from '$lib/store.js';
	import Flatpickr from './FlatPickr.svelte';
	import dayjs from 'dayjs';
	export let date;
	export let icon = false;
	export let mode = 'single';
	export let onClose;
	export let element = '#my-picker' + Math.round(Math.random() * 1000);
	const flatpickrOptions = {
		mode,
		element,
		enableTime: false,
		altInput: true,
		wrap: true,
		altFormat: 'D M j',
		dateFormat: 'Y-m-d',
		onClose
	};
</script>

<svelte:head>
	<link rel="stylesheet" type="text/css" href="/flatpickr-dark.css" />
</svelte:head>

<Flatpickr options={flatpickrOptions} value={date || new Date()} {element}>
	<div
		class="flatpickr relative z-10 focus:outline-none uppercase focus:shadow-outline bg-[#060f1f] hover:bg-gray-800 rounded-full"
		id={element.slice(1)}
	>
		<input
			type="text"
			placeholder="Date"
			data-input
			class="w-full p-2 {$IsNative
				? 'w-36 h-10'
				: 'text-xs'} text-center cursor-pointer leading-none rounded-full text-gray-300 font-medium"
		/>
		{#if dayjs(date) > dayjs().subtract(4, 'hour').startOf('day')}
			<div
				class="absolute centreInContext pointer-events-none p-1 px-8 capitalize {$IsNative
					? 'h-8'
					: 'text-xs'} bg-inherit text-gray-300 font-medium"
			>
				Today
			</div>
		{/if}
		{#if icon}
			<div class="absolute top-0 right-0 px-3 py-2">
				<svg class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0
            00-2 2v12a2 2 0 002 2z"
					/>
				</svg>
			</div>
		{/if}
	</div>
</Flatpickr>
