<script>
	export let metric;
	export let color = '#64b0ff';
	export let loaded;
	export let name = 'brainpower';
	// export let round;
	// export let metricOnly = false;
</script>

<div class="wrapper">
	<div class="loading" />
	<div class="loading_before" />
	<div class="dark_inner">
		<div
			style="padding-top: 6.4rem;transform: translate3d(0,0,0);"
			class="absolute w-full h-full px-2 rounded-full left-0 bottom-0 overflow-hidden"
		>
			<slot />
		</div>
	</div>
	{#key metric}
		<div class="absolute grid text-center items-center justify-items-center">
			{#if !loaded && !metric}
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="h-12 w-12"
					fill="none"
					viewBox="0 0 24 24"
					stroke="url(#powerSVG-gradient)"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M13 10V3L4 14h7v7l9-11h-7z"
					/>
					<defs>
						<linearGradient id="powerSVG-gradient" x1="0" y1="0" x2="0" y2="1">
							<stop offset="0%" stop-color={color} />
							<stop offset="50%" stop-color="white" />
							<stop offset="100%" stop-color={color} />
						</linearGradient>
					</defs>
				</svg>
			{:else if metric}
				<span
					id="powerMetric"
					class="power"
					style="font-size:3.5rem;
      line-height:3rem;font-weight:normal;"
				>
					{metric}
				</span>
			{:else}
				<svg
					style="filter: hue-rotate(
				120deg
				) saturate(1.5);"
					xmlns="http://www.w3.org/2000/svg"
					class="h-12 w-12"
					viewBox="0 0 20 20"
					fill="url(#powerSVG-gradient)"
				>
					<path
						fill-rule="evenodd"
						d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
						clip-rule="evenodd"
					/>
					<defs>
						<linearGradient id="powerSVG-gradient" x1="0" y1="0" x2="0" y2="1">
							<stop offset="0%" stop-color={color} />
							<stop offset="50%" stop-color="white" />
							<stop offset="100%" stop-color={color} />
						</linearGradient>
					</defs>
				</svg>
			{/if}
			<span class="power uppercase text-sm tracking-tight font-semibold"> {name} </span>
		</div>
	{/key}
</div>

<style>
	.wrapper {
		position: relative;
		height: 11rem;
		width: 10.9rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
	}

	.loading_before {
		width: 100%;
		height: 100%;
		content: ' ';
		border-radius: 50%;
		box-shadow: 0 0 20px 10px #64b0ff44;
		position: absolute;
	}
	.loading {
		color: #000;
		background: linear-gradient(0deg, #64b0ff, white, #64b0ff);
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: relative;
		box-shadow: 0 0 5px 0px #64b0ff22, inset 0 0 2.5rem 1px #64b0ff;
		/* animation: rotate_animation 7s ease-in-out infinite alternate,
			pulse_animation 3s ease-out infinite; */
	}
	.dark_inner {
		width: calc(100% - 7px);
		height: calc(100% - 7px);
		background-color: #0f051f;
		content: ' ';
		position: absolute;
		top: 3px;
		left: 3px;
		border-radius: 50%;
		box-shadow: 0 0 5px 0px #64b0ff22, inset 0 0 2.5rem 1px #64b0ff;
		/* animation: pulse_animation 1.5s ease-out infinite;
		animation-delay: 0.2s; */
	}
	/* 
	@keyframes rotate_animation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(600deg);
		}
	}

	@keyframes pulse_animation {
		0%,
		40% {
			box-shadow: 0 0 5px 0px #64b0ff22, inset 0 0 2.5rem 1px #64b0ff;
		}
		100% {
			box-shadow: 0 0 8px 2rem #64b0ff00, inset 0 0 2.5rem 1px #64b0ff;
		}
	} */
</style>
