<script>
	import dayjs from 'dayjs';
	import Echart from './Echart.svelte';

	export let color = '#64b0ff';
	let color2 = '#bF729a';

	let longDay19Hours = 60 * 19; // 19 hours in minutes

	export let dataIndex;
	export let data;
	dataIndex = dataIndex || Math.floor((data?.length || 1130) / 2);
	data = data && data.slice(0, dataIndex < 1100 ? longDay19Hours : data?.length);
	let loadingData1 = Array(1130)
		.fill()
		.map((a, i) => 40 + 5 * Math.sin(i / 60));
	$: lineData = (data && data.map((p) => p.value)) || loadingData1;

	let category =
		(data && data.map((p) => p.timestamp)) ||
		Array(1130)
			.fill()
			.map((a, i) => dayjs().subtract(570, 'minute').add(i, 'minute'));
	$: if (dataIndex > 1100) {
		lineData = lineData.slice(0, dataIndex < 1100 ? longDay19Hours : lineData?.length);
		category.slice(0, dataIndex < 1100 ? longDay19Hours : lineData?.length);
	}
	export let powerPeaks;
	export let powerDips;
	export let divName = 'sparkLine';
	// $: console.log({
	// 	powerDips,
	// 	powerPeaks,
	// 	dataIndex,
	// 	lineData,
	// 	category,
	// 	divName
	// });
	let action = {
		type: 'highlight',

		// Find  by index or id or name.
		// Can be an array to find multiple components.
		seriesIndex: 0,
		dataIndex
	};
	$: action = {
		type: 'highlight',

		// Find  by index or id or name.
		// Can be an array to find multiple components.
		seriesIndex: 0,
		dataIndex
	};
	export let name = undefined;
	export let showAxis = undefined;
	$: option = {
		animation: true,
		grid: {
			left: 0,
			top: 5,
			right: 0,
			bottom: showAxis ? 25 : 0
		},
		tooltip: false,
		legend: {
			data: [],
			textStyle: {
				color: '#ccc'
			}
		},
		xAxis: {
			data: category,
			show: showAxis,
			axisLine: {
				lineStyle: {
					color: color + '44'
				}
			},
			axisLabel: {
				formatter: (value) => dayjs(value).format('HH:mm'),
				interval: (index, value) => {
					let [hours, minutes] = dayjs(value).format('HH:mm').split(':');
					return (
						Number(hours) % (lineData?.length <= longDay19Hours ? 3 : 6) === 0 && minutes === '00'
					);
				}
			}
		},
		yAxis: {
			show: false,
			min: 0,
			// function (value) {
			// 	return Math.floor(value.min / 10 - 1.5) * 10;
			// },
			max: 100,
			splitLine: { show: false },
			axisLine: {
				lineStyle: {
					color: '#ccc'
				}
			}
		},
		series: [
			{
				name,
				type: 'line',
				smooth: true,
				showSymbol: false,
				animationDurationUpdate: 1000,
				markArea: {
					silent: true,
					animation: true,
					zLevel: 20,
					label: {
						show: true,
						// formatter: "a {a}, b {b}, c {c}, value {value}",
						position: 'insideTop'
					},
					itemStyle: {
						opacity: 0.5,
						color: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [
								{
									offset: 0.4,
									color: color + '00' // color at 0%
								},
								{
									offset: 1,
									color: color + '66' // color at 100%
								}
							],
							global: false // default is false
						},
						// `url(#zr2323-gradient-${color.slice(1) + divName}2)`,
						borderWidth: 2,
						borderColor: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [
								{
									offset: 0.4,
									color: color + '00' // color at 0%
								},
								{
									offset: 1,
									color: color + '66' // color at 100%
								}
							],
							global: false // default is false
						},
						//  `url(#zr2323-gradient-${color.slice(1) + divName}2)`,
						borderType: 'solid',
						borderJoin: 'round'
					},
					data: [
						...(powerPeaks || [])
							.filter((peak) => peak.position + 90 < lineData?.length)
							.map((peak) => {
								return [
									{
										xAxis: dayjs(peak.timestamp).subtract(90, 'minutes').format()
									},
									{
										xAxis: dayjs(peak.timestamp).add(90, 'minutes').format()
									}
								];
							}),
						...(powerDips || [])
							.filter((peak) => peak.position + 90 < lineData?.length)
							.map((peak) => {
								return [
									{
										xAxis: dayjs(peak.timestamp).subtract(90, 'minutes').format(),
										itemStyle: {
											opacity: 0.5,
											color: {
												type: 'linear',
												x: 0,
												y: 0,
												x2: 0,
												y2: 1,
												colorStops: [
													{
														offset: 0.4,
														color: color2 + '00' // color at 0%
													},
													{
														offset: 1,
														color: color2 + '66' // color at 100%
													}
												],
												global: false // default is false
											},
											// `url(#zr2323-gradient-${color2.slice(1) + divName}2)`,
											borderWidth: 2,
											borderColor: {
												type: 'linear',
												x: 0,
												y: 0,
												x2: 0,
												y2: 1,
												colorStops: [
													{
														offset: 0.4,
														color: color2 + '00' // color at 0%
													},
													{
														offset: 1,
														color: color2 + '66' // color at 100%
													}
												],
												global: false // default is false
											},
											// `url(#zr2323-gradient-${color2.slice(1) + divName}2)`,
											borderType: 'solid',
											borderJoin: 'round'
										}
									},
									{
										xAxis: dayjs(peak.timestamp).add(90, 'minutes').format()
									}
								];
							})
					]
				},
				//         symbol: 'emptyCircle',
				//         symbolSize: 15,
				data: lineData,
				itemStyle: { color },
				areaStyle: {
					color: {
						type: 'linear',
						x: 0,
						y: 0,
						x2: 0,
						y2: 1,
						colorStops: [
							{
								offset: 0,
								color: color + 66 // color at 0%
							},
							{
								offset: 1,
								color: color + '00' // color at 100%
							}
						],
						global: false // default is false
					},
					// `url(#zr2323-gradient-${color.slice(1) + divName})`,
					emphasis: {
						color: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [
								{
									offset: 0,
									color: color + 66 // color at 0%
								},
								{
									offset: 1,
									color: color + '00' // color at 100%
								}
							],
							global: false // default is false
						}
						// `url(#zr2323-gradient-${color.slice(1) + divName})`
					}
				}
			}
		]
	};
</script>

<Echart {option} {action} {divName} minHeight={showAxis ? '7rem' : '4rem'} />
<svg style="position:fixed;height:0;width:0;">
	<defs>
		<linearGradient
			id="zr2323-gradient-{color.slice(1) + divName}"
			x1="0"
			y1="0"
			x2="0"
			y2="1"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="0%" stop-color={color + '66'} />
			<stop offset="100%" stop-color={color + '00'} />
		</linearGradient>
		<linearGradient
			id="zr2323-gradient-{color.slice(1) + divName}2"
			x1="0"
			y1="0"
			x2="0"
			y2="1"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="0%" stop-color={color + '00'} />
			<stop offset="100%" stop-color={color + '66'} />
		</linearGradient>
		<linearGradient
			id="zr2323-gradient-{color2.slice(1) + divName}2"
			x1="0"
			y1="0"
			x2="0"
			y2="1"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="40%" stop-color={color2 + '00'} />
			<stop offset="100%" stop-color={color2 + '66'} />
		</linearGradient>
	</defs>
</svg>
