<script>
	import dayjs from 'dayjs';
	import { Data } from '$lib/store.js';

	import IncreasePower from '../components/IncreasePower.svelte';

	import TimeTracked from '../components/charts/TimeTracked.svelte';

	$: avgSwitchRate =
		Math.round(
			10 *
				Object.values($Data[`["timeseries","time_series/activitywatch","noCache"]`] || [])
					?.filter((a) => a?.productivityMetrics?.productivityScore)
					?.reduce(
						(a, b, i, ar) => a + (b?.productivityMetrics?.contextSwitchRate || 0) / ar.length || 1,
						0
					)
		) / 10;
	$: avgSessionLength =
		Math.round(
			10 *
				Object.values($Data[`["timeseries","time_series/activitywatch","noCache"]`] || [])
					?.filter((a) => a?.productivityMetrics?.productivityScore)
					?.reduce(
						(a, b, i, ar) => a + (b?.productivityMetrics?.sessionLength || 0) / (ar.length || 1),
						0
					)
		) / 10;
	$: partOfDay =
		dayjs().hour() < 12 && dayjs().hour() > 4
			? 'morning'
			: dayjs().hour() < 18 && dayjs().hour() > 4
			? 'afternoon'
			: dayjs().hour() < 22 && dayjs().hour() > 4
			? 'evening'
			: 'night' || $Data;
	// $: console.log(
	// 	{ avgSwitchRate, avgSessionLength },
	// 	Object.values($Data[`["timeseries","time_series/activitywatch","noCache"]`] || [])?.filter(
	// 		(a) => a?.productivityMetrics?.productivityScore
	// 	)
	// );

	$: insights = [
		{
			operator: 'You ',
			attributes: ['context switch', 'deep work'],
			link:
				avgSwitchRate +
				' times / hour. ' +
				'You ' +
				(avgSwitchRate > 14.6 ? 'lose ' : 'gain ') +
				Math.round(Math.abs(avgSwitchRate - 14.6) * 5) +
				' minutes of ',
			end: 'per day vs the average person (14.6/hour).'
			// comparisonValues: [8653, 7134]
		},
		{
			operator: 'Your average ',
			attributes: ['session length', 'deep work'],
			link:
				' is ' +
				Math.round(avgSessionLength / 60) +
				' minutes. ' +
				'You ' +
				(avgSessionLength < 24 * 60 ? 'lose ' : 'gain ') +
				Math.round(4 * Math.abs(avgSessionLength / 60 - 24)) +
				' minutes of ',
			end: 'per day vs the average person (24m).'
			// comparisonValues: [8653, 7134]
		}
	];

	export let insights;
	function scrollToNow(node) {
		let height = window?.innerHeight;
		let nowAsDecimal =
			(dayjs().subtract(4, 'hour').hour() * 60 + dayjs().subtract(4, 'hour').minute()) / (24 * 60);
		node.scroll({
			top: height * nowAsDecimal - 80,
			left: 0,
			behavior: 'smooth'
		});
	}

	let bigMetrics = ['Total Time'];
	export let datesToAnalyse;
</script>

<div>
	<div class="pt-2 px-8 overflow-hidden">
		<TimeTracked {datesToAnalyse} show={['bigMetrics', 'activities']} divName="Sidebar" />
	</div>
	<div class="px-6 ">
		<IncreasePower />
	</div>
</div>

<!-- 
<div
	class="font-medium rounded-full px-2 absolute z-10 centreInContext top-16 s-ZXJWmVbT1-6l"
	style="
    /* opacity: 0.7; */
    text-transform: uppercase;
    font-size: 0.7rem;
    top: 4.25rem;
	background:#142233;
"
>
	Current Session
</div>
<div class="px-5 flex flex-col text-sm items-stretch gap-2">
	<IncreasePower />
	{#each insights as insight}
		<InsightCard {insight} />
	{/each}
</div>
<card class="funkyCard relative mt-auto w-full pb-24">
	<div class="font-semibold text-sm mb-2 px-2 ">Today</div>
	<div
		use:scrollToNow
		class="hover:bg-blue-600 px-2 hover:bg-opacity-10 cursor-pointer h-[20vh] relative w-full bg-transparent overflow-scroll rounded "
	>
		<CalendarFullScreen showTrackingButtons={false} />
	</div>
</card> -->
<style>
	/* .funkyCard:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 0;
		background: #222222;
		mix-blend-mode: color-dodge;
		border-radius: 20px 20px 40px 0px;
	} */
	/* .funkyCard {
		height: 100%;
		box-shadow: 0px -10px 10px -10px #2e69fc;
		border-radius: 20px 20px 20px 0px;
		background: transparent;
	} */
</style>
